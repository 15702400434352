import React, { useEffect, useState } from 'react';
import BackToAction from '../general/backToAction';
import right_arrow from '../../assets/images/svg/arrow-right.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomCheckBox from '../general/customCheckbox';
import InformationContent from '../informationContent';
import { EmailLogo, EmailTemplateBanner } from '../../config/images';
import { useDispatch } from 'react-redux';
import { BaseUrl } from '../../config';
import EmailModal from '../general/emailModal';

import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import TopTip from './topTip';
import { getData, setData } from '../../utils/storage';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake'
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const TelephoneSupportPromptsContainer = (props) => {


  const [emailModal, setEmailModal] = useState(false);
  const [templateKey, setTemplateKey] = useState('');
  const navigate = useNavigate();
  const _location = useLocation();
  const [promptsData, setPromptsData] = useState(_location.state.data);
  const goToPromptsPage = () => {
    setData('inputFields', []);
    navigate('/get-advice/telephone-support', { state: { path: 'telephone-prompt', telephoneKey: _location.state.templateType } });
  }


  const onBackPress = () => {
    navigate('/get-advice/telephone-support', { state: { path: 'telephone-prompt' } });
  }

  const dispatch = useDispatch();

  const toastMessage = (toastMessage) => {
    toast(toastMessage);

  }

  useEffect(() => {

    getData('telephoneKey').then((res) => {
      if (res !== undefined && res !== '') {
        setTemplateKey(res)

      }
    }
    )

  }, [])



  const onCheckboxselected = (id) => {
    setPromptsData(prevInputs =>
      prevInputs.map(input => (input.id === id ? { ...input, selected: !input.selected } : input))
    );
  }
  const onDiscardClick = () => {
    setPromptsData(prevInputs =>
      prevInputs.map(input => ({ ...input, selected: false }))
    );
  }

  const LogoImage = `${BaseUrl}/email/Logo.png`;
  const BannerTemplage = `${BaseUrl}/email/template-banner.png`;




  const htmlContent = `
  <html>
    <head></head>
    <body style="background-color: #fff; font-family: Helvetica, Arial, sans-serif;">
        <div>
            <img width="100" style="width: 100%;" src=${BannerTemplage} alt="banner" />
        </div>
        <table style="width: 95%; padding: 0 1.313rem; margin: 0 auto; display: block;">
            <tr style="display: block; width: 100%" >
                <td style="display: block; margin-top: 1.688rem;">
                    <img src="${LogoImage}" style="width: 10%; min-width: 7rem" alt="TDS Logo" />
                </td>
            </tr>
            <tr style="margin-top: 3.188rem; display: block;">
                <td><p style="min-width: 18.75rem;">Hello,</p></td>
            </tr>
            <tr style="margin-top: 3.188rem;">
                <td>
                    <p style="min-width: 18.75rem;">Thank you for taking the time to visit our support website for people who have issues with their rental property.</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p style="min-width: 18.75rem;">As your recommendation from <a href="#" title="TDS" style="color: inherit; font-weight: 700;">TDS</a> was to hold a telephone call to try to resolve your rental issue. You can use these prompts to help frame your telephone conversation.</p>
                </td>
            </tr>
            <tr>
                <td>
                    <p style="min-width: 18.75rem;">We recommend you take additional notes on when the call took place and what was discussed.</p>
                </td>
            </tr>
            <tr>
                <td>
                    <h3 style="font-size: 1rem; line-height: 1.563rem; font-weight: 700; margin-bottom: 1.125rem;">Call to your Landlord</h3>
                </td>
            </tr>
        </table>
        ${Array.isArray(promptsData) && promptsData.length > 0 ? `
        <table style="width: 95%; margin: 0 auto; padding: 1.313rem; display: block; background-color: #FAFAFA;">
            ${promptsData.map(item => `
            <tr style="display: block; margin-bottom: 1rem;">
                <td style="vertical-align: top; display: inline-block; width: 49%; min-width: 9.375rem">
                    <h3 style="font-weight: 700; font-size: 1rem; margin-right: 2.375rem; min-width: 9.25rem; line-height: 1.563rem; margin-top: 0">${item.label}</h3>
                </td>
                <td style="vertical-align: top; display: inline-block; width: 49%; min-width: 9.375rem">
                    <p style="margin: 0">${item.value}</p>
                </td>
            </tr>
            `).join('')}
        </table>
        ` : ''}
        <table style="width: 95%; padding: 0 1.313rem; margin: 1rem auto 3rem; display: block;">
        <tbody style="display: block; width: 100%">
            <tr style="display: block; width: 100%">
                <td style="display: block; margin-top: 1rem;">
                    <p style="margin-bottom: 0">For more information and resources please <a href='https://www.tdsfoundation.org.uk' target="_blank" style="font-weight: bold; text-decoration: underline; color: #3d4852">visit our website.</a></p>
                </td>
            </tr>
            <tr style="display: block; width: 100%">
                <td style="display: block; margin-top: 1rem;">
                    <p style="margin-bottom: 0">Best wishes,</p>
                </td>
            </tr>
            <tr style="display: block; width: 100%">
                <td style="display: block; margin-top: 1rem;">
                    <p style="margin-bottom: 0"><i>My Housing Gateway Team</i></p>
                </td>
            </tr>
            <tr style="display: block; width: 100%">
                <td style="display: block; margin-top: 1.688rem;">
                    <img src="${LogoImage}" style="width: 10%; min-width: 7rem" alt="TDS Logo" />
                </td>
            </tr>
            </tbody>
        </table>
    </body>
  </html>
  `;


  const printContent = `
  <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "https://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <html xmlns="https://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
  <head>
    <style>
      * { 
        margin: 0;
        padding: 0;
      }
      body {
        width: 100%;
        font-size: 1.5vw;
        font-family: Helvetica, Arial, sans-serif;
      }
      h3 {
        font-size: 1.5em;
        font-weight: 700;
        margin-bottom: 1rem;
      }
      p, a {
        font-size: 1.2em;
        margin-top: 0;
      }
      .prompts-container {
        margin: 0 auto;
        padding: 1rem;
        background-color: #FAFAFA !important;
      }
      img {
        width: 100%;
        max-width: 100%;
      }
      @media print {
        .prompts-container {
          background-color: #FAFAFA !important;
        }
      }
    </style>
  </head>
  <body>
    <div style="width: 100%; display: block;">
      <img width="100" style="width: 100%;" src="${EmailTemplateBanner}" alt="banner" />
    </div>
    
    ${Array.isArray(promptsData) && promptsData.length > 0 ? `
    <div style="width: 95%; margin: 0 auto; padding: 1.313rem; background-color: #FAFAFA;" class="prompts-container">
      ${promptsData.map(item => `
      <div style="margin-bottom: 1rem; display: flex; justify-content: space-between; align-items: flex-start;">
        <div style="vertical-align: top; width: 49%;">
          <h3 style="margin-top: 0;">${item.label}</h3>
        </div>
        <div style="vertical-align: top; width: 49%;">
          <p style="margin-bottom: 0;">${item.value}</p>
        </div>
      </div>
      `).join('')}
    </div>
    ` : ''}
    
    </div>
  </body>
</html>
`;

  const isHTML = (str) => {
    const regex = /<\/?[a-z][\s\S]*>/i;
    return regex.test(str);
  };

  const downloadClick = () => {
    // generatePDF(printContent);
    const _content = [


      // Add more content sections as needed
    ];
    if (Array.isArray(promptsData) && promptsData.length > 0) {

      _content.push({
        table: {
          widths: ['29%', '69%'],
          body: promptsData.map(item => {
            let isvalue = item.value.length > 0 ? isHTML(item.value) : false;
            let formatedObjectArry = [];
            if (isvalue) {
              let temp = htmlToPdfmake(item.value, { ignoreStyles: ['font-family', 'font', 'margin'] });

              if (temp.length > 0) {

                temp = temp.map((item) => {

                  if (typeof item.text === 'string') {
                    formatedObjectArry.push({
                      text: item.text + " \n",
                      style: 'dynamicTextStyle',
                      bold: item.hasOwnProperty('bold') ? item.bold : false,
                      nodeName: item.hasOwnProperty('nodeName') ? item.nodeName == 'STRONG' ? item.nodeName : '' : '',
                    })
                    return item;
                  } else if (typeof item.text === 'object') {

                    let _text = {
                      text: [],
                      style: 'dynamicTextStyle',
                      nodeName: '',
                      fontSize: '',
                    };
                    let temptext = item.text.map((item1, index) => {

                      if (item1.text !== '' && item1.text !== ' ') {
                        if (isvalue) {
                          if (index === 0) {
                            _text.text.push(item1);
                          } else {
                            _text.text.push(item1);
                          }
                        }

                        return item1;

                      }
                    });

                    formatedObjectArry.push(_text);

                    return temptext;
                  }
                });

              }
            }
            return [
              { text: item.label, bold: true, alignment: 'left' },
              { text: isvalue ? formatedObjectArry : item.value, alignment: 'left' }
            ]
          }),
        },
        layout: {
          hLineWidth: () => 0,
          vLineWidth: () => 0,
          paddingLeft: () => 20,
          paddingRight: () => 20,
          paddingTop: () => 20,
          paddingBottom: () => 20,
        },
        fillColor: '#FAFAFA',
        margin: [0, 0, 0, 10]
      });
    }
    let letterBottom = [


      {
        text: ['For more information and resources please visit our website.', { text: 'website', bold: true, link: 'http://your-link.com' }],
        style: 'textStyle'
      },
      {
        text: 'Best wishes,',
        style: 'textStyle'
      },
      {
        text: 'My Housing Gateway Team',
        italic: true,
        style: 'textStyle'
      },
      {

        image: EmailLogo, // Base64 encoded image
        width: 100,
        margin: [0, 15, 10, 15] // Adjust margins as needed

      },
    ]

    const docDefinition = {
      pageMargins: [90, 80, 80, 40], // [left, top, right, bottom]
      header: (currentPage, pageCount) => {
        return {
          image: EmailTemplateBanner, // Base64 encoded image
          width: 595.28, // Full page width for A4 size
          margin: [0, 0, 20, 20], // Adjust margins as needed
        };
      },
      content: _content,
      styles: {
        textStyle: {
          fontSize: 11,
          lineHeight: 14.52 / 11, // Adjust line height relative to font size
          fontWeight: 400,
          margin: [5, 15, 0, 0], // Margin top of 15px to separate each block
          wordBreak: 'break-word'
        },
        innerTextStyle: {
          fontSize: 11,
          lineHeight: 14.52 / 11, // Adjust line height relative to font size
          fontWeight: 400,
          margin: [0, 5, 0, 5], // Margin top of 15px to separate each block
          wordBreak: 'break-word'
        },
        dynamicTextStyle: {
          fontSize: 11,
          lineHeight: 14.52 / 11, // Adjust line height relative to font size
          fontWeight: 700,
          wordBreak: 'break-word',
          margin: [0, 5, 0, 0] // Margin top of 15px to separate each block
        }

      }
    };

    pdfMake.createPdf(docDefinition).download('Generated-telephone-support.pdf');


    window.gtag('event', 'download_telephone_support', {
      'label': 'Download Telephone Prompt',
      'screen_name': 'Telephone Prompt',
      'value': 'Download'
    });
  }


  const emailClick = () => {
    setEmailModal(true);
  }



  const print = () => {
    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.write(printContent);
    printWindow.document.title = 'telephone-support'
    printWindow.document.close();
    printWindow.focus();

    const images = printWindow.document.images;
    const totalImages = images.length;
    let loadedImages = 0;

    if (totalImages === 0) {
      printWindow.print();
      printWindow.close();
    } else {
      for (let i = 0; i < totalImages; i++) {
        images[i].onload = () => {
          loadedImages++;
          if (loadedImages === totalImages) {
            printWindow.print();
            printWindow.close();
          }
        };
        images[i].onerror = () => {
          loadedImages++;
          if (loadedImages === totalImages) {
            printWindow.print();
            printWindow.close();
          }
        };
      }
    }

    window.gtag('event', 'print_telephone_support', {
      'label': 'Print Telephone Prompt',
      'screen_name': 'Telephone Prompt',
      'value': 'Print'
    });
  }

  const copiedPlainText = `
  <body style="background-color: #fff;">
  
  ${Array.isArray(promptsData) && promptsData.length > 0 ? `
  <table style="width: 85%; margin: 0 auto; padding: 1.313rem; display: block; background-color: #FAFAFA;" class="prompts-table">
    ${promptsData.map(item => `
    <tr style="margin-bottom: 1rem;">
      <td style="vertical-align: top; width: 49%; min-width: 9.375rem;">
        <h3>${item.label}</h3>
      </td>
      <td style="vertical-align: top; width: 49%; min-width: 9.375rem;">
        <p>${item.value}</p>
      </td>
    </tr>
    `).join('')}
  </table>
  ` : ''}
  
</body>
  `

  const copyToClipboard = () => {
    const type = "text/html";
    const blob = new Blob([copiedPlainText], { type });
    const data = [new ClipboardItem({ [type]: blob })];

    window.gtag('event', 'copy_telephone_support', {
      'label': 'Copy Telephone Prompt',
      'screen_name': 'Telephone Prompt',
      'value': 'Copy'
    });
    
    navigator.clipboard.write(data)
      .then(() => {
        toastMessage('Copied to the clipboard');
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };


  return (
    <>
      <div className='bg-cream telePhoneContainer'>
        <div className="container bg-white">
          <BackToAction link={"/get-advice/telephone-support"} />
          <div className='content-container col-12 col-md-12'>
            <h3 className='title-26 black'>Your telephone prompts</h3>
            <div className='d-flex justify-content-between'>
              <p className='text-normal black col-12 col-md-6 descp-text'>We have generated a series of prompts and reminders to help you on the call - please review and export as required.</p>
              <div className='d-flex col-6 col-mb-6 d-none d-md-flex justify-content-end'>
                <button className='smallButton text-16-bold'
                  onClick={() => downloadClick()}
                >
                  Download
                </button>
                <button className='smallButton text-16-bold'
                  onClick={() => copyToClipboard()}
                >
                  Copy
                </button>
                <button className='smallButton text-16-bold' onClick={() => emailClick()}>
                  Email
                </button>
                <button className='smallButton text-16-bold'
                  onClick={() => print()}
                >
                  Print
                </button>
              </div>
            </div>
            <TopTip templateType={_location.state !== null && _location.state.templateType !== undefined ? _location.state.templateType : templateKey !== '' ? templateKey : ''} />

            {
              promptsData.map(obj => {

                return <CustomCheckBox
                  key={obj.id}
                  label={obj.label}
                  value={obj.value}
                  selected={obj.selected}
                  onClick={() => onCheckboxselected(obj.id)}
                />
              })
            }
            <div className='mt-4'>
              <InformationContent title={"We recommend you take additional notes on when the call took place and what was discussed"} description={"You can choose to download a copy of this telephone script, print it or email it directly to the relevant council team. When you do this, we suggest attaching photo evidence and other relevant correspondence to the same email, in order to support your complaint."} />

            </div>
            <div className='d-flex col-12 d-flex d-md-none mt-4 justify-content-between'>
              <button className='smallButton text-16-bold'
                onClick={() => downloadClick()}
                style={{ flexBasis: '22%', margin: 0 }}>
                Download
              </button>
              <button className='smallButton text-16-bold'
                onClick={() => copyToClipboard()}
                style={{ flexBasis: '22%', margin: 0 }}>
                Copy
              </button>
              <button className='smallButton text-16-bold'
                onClick={() => emailClick()}
                style={{ flexBasis: '22%', margin: 0 }}>
                Email
              </button>
              <button className='smallButton text-16-bold'
                onClick={() => print()}
                style={{ flexBasis: '22%', margin: 0 }}>
                Print
              </button>
            </div>
            <div className='d-flex justify-content-between buttonContainer'>
              <button className='discardButton square border border-2 rounded text-20' onClick={() => onBackPress()}>Back</button>
              <button className='generateButton text-20' onClick={() => goToPromptsPage()}>
                Try again
                <img src={right_arrow} style={{ height: '2.563rem', width: '2.563rem', marginLeft: '2.5rem', verticalAlign: 'middle' }} />
              </button>
            </div>
          </div>
        </div>
      </div>


      <ToastContainer />

      <EmailModal
        show={emailModal}
        type={"telephone"}
        onHide={() => setEmailModal(false)}
        htmlContent={htmlContent}
        title={"Please provide your email address so we can send your summary"}
      />
    </>
  )
}

export default TelephoneSupportPromptsContainer