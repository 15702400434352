import * as type from "./types"

export const fetchActionPlanData = (res) => {
    return { type: type.ACTION_PLAN_REQUEST, payload: res }
}

export const resetActionPlanData = () => {
    return { type: type.RESET_ACTION_PLAN, payload: null }
}

// common problem action plan 

export const fetchCommonActionPlanData = (res) => {
    return { type: type.COMMON_ACTION_PLAN_REQUEST, payload: res }
}

export const resetCommonActionPlanData = () => {
    return { type: type.RESET_COMMON_ACTION_PLAN, payload: null }
}