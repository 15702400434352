import moment from "moment/moment";

export const templeteData = {
    reportLandLord: {
        maxLeftIndex: 5,
        warning: false,
        description: '<p>We can help you build a letter to your Landlord - we just need a few extra details from you.</p>',
        builderInfo: {
            title: 'We do not store any of this data or send anything on your behalf',
            description: '<p>The following information is used purely to generate a personalised letter based on our template file. We will never send or store any of this data on your behalf.</p>'
        },
        generatedLetterInfo: {
            postion: 'bottom',
            title: 'We recommend you add additional photos, evidence & correspondence',
            description: '<p>You can choose to download a copy of this letter, print it or email it directly to the relevant council team. When you do this, we suggest attaching photo evidence and other relevant correspondence to the same email, in order to support your complaint.</p>'
        },
        inputFields: [
            {
                title: 'Address of property',
                short_descp: '<p>First line of address</p>',
                placeholderTxt: '123 street name',
                type: 'text',
                value: '',
                position: '1',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            }, {
                title: 'Postcode',
                short_descp: '<p>Postcode.</p>',
                placeholderTxt: 'AB12 123',
                type: 'text',
                value: '',
                position: '2',
                letterPosition: '4',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Name of landlord / letting agent',
                short_descp: '',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '3',
                letterPosition: '1',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Address of landlord / letting agent',
                short_descp: '',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '4',
                letterPosition: '2',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Postcode of landlord / letting agent',
                short_descp: '',
                placeholderTxt: 'AB12 123',
                type: 'text',
                value: '',
                position: '5',
                letterPosition: '2',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Your name',
                short_descp: '<p>Or the name of the tenant if on someone else’s behalf.</p>',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '6',
                letterPosition: '8',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Your contact details',
                short_descp: '<p>Or the contact details of the tenant if writing on someone else’s behalf.</p>',
                placeholderTxt: 'Phone number or email address',
                type: 'text',
                value: '',
                position: '7',
                letterPosition: '10',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'The repair or maintenance issue',
                short_descp: '',
                placeholderTxt: '',
                type: 'ck-editor',
                value: '',
                position: '8',
                letterPosition: '4',
                isValid: true,
                toolTip: '',
            },

        ],
        letterContaint: [
            {
                title: 'Request for repairs / maintenance',
                postion: 0,
                default: '',
                valueOF: '',
                alignment: 'left',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                postion: 1,
                default: 'Landlord / Letting Agent name',
                valueOF: 'Name of landlord / letting agent',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 2,
                default: 'Landlord / Letting Agent address',
                valueOF: 'Address of landlord / letting agent',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 2,
                default: 'Landlord / Letting Agent Postcode',
                valueOF: 'Postcode of landlord / letting agent',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 3,
                default: moment(new Date()).format("DD/MM/YYYY"),
                valueOF: 'Date',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 4,
                default: 'Name of landlord / letting agent',
                valueOF: 'Name of landlord / letting agent',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: 'Dear ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'Please can you resolve my housing issue?',
                position: 5,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 5,
                default: 'Address of property',
                valueOF: 'Address of property',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 5,
                default: 'Postcode',
                valueOF: 'Postcode',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'I am the tenant of the above property and I am writing to let you know that the following repairs are needed',
                position: 6,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 7,
                default: 'The repair or maintenance issue',
                valueOF: 'The repair or maintenance issue',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 8,
                default: 'Your contact details',
                valueOF: 'Your contact details',
                alignment: 'side-right',
                rightdescription: ' to confirm how long you expect repairs to take and who will do the repair work.',
                leftdescription: 'Please would you contact me on ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

            {
                title: 'Yours sincerely ',
                position: 9,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 10,
                default: 'Name of tenant',
                valueOF: 'Your name',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'Tenant',
                position: 11,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 12,
                default: 'Your address',
                valueOF: 'Address of property',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 13,
                default: 'Your contact details',
                valueOF: 'Your contact details',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

        ]
    },
    tel_repairOrMaintain: {
        warning: false,
        inputFields: [
            {
                label: 'Introduce yourself',
                description: 'Provide your name and address of the property you are renting.',
                isValid: true,
                type: 'ck-editor',
                _className: 'col-12 col-md-8',
                selected: false,
                value: '',
                placeholder: 'Use the notes box to remind yourself of any key points you want to say, as well as their response',
                id: 1
            },
            {
                label: 'Briefly explain the reason for your call',
                description: 'To let them know that there are repairs needed in the property',
                isValid: true,
                type: 'ck-editor',
                _className: 'col-12 col-md-8',
                selected: false,
                value: '',
                placeholder: 'Use the notes box to remind yourself of any key points you want to say, as well as their response',
                id: 2
            },


            {
                label: 'Give details of the issue',
                description: 'We recommend giving as much detail as possible',
                isValid: true,
                type: 'ck-editor',
                selected: false,
                _className: 'col-12 col-md-8',
                value: '',
                placeholder: '',
                id: 3
            },
            {
                label: 'Tell them how they can put it right',
                description: 'Use the below prompts to be clear about your expectations resolving the issue',
                isValid: true,
                type: 'ck-editor',
                selected: false,
                _className: 'col-12 col-md-8',
                value: '',
                placeholder: 'e.g. carry out the repair works',
                id: 4
            },
            {
                label: 'Ask',
                description: 'How long they expect the repairs will take and who will carry out the works',
                isValid: true,
                type: 'ck-editor',
                selected: false,
                _className: 'col-12 col-md-8',
                value: '',
                placeholder: 'Use the notes box to remind yourself of any key points you want to say, as well as their response',
                id: 5
            },
        ]
    },
    complainCouncil_Housing: {
        maxLeftIndex: 4,
        warning: false,
        description: '<p>If you have reported an issue to your landlord and you do not think they have taken the appropriate action, you can make a formal complaint to your landlord to let them know what you think has gone wrong.</p>',
        builderInfo: {
            title: 'We do not store any of this data or send anything on your behalf',
            description: '<p>The following information is used purely to generate a personalised letter based on our template file. We will never send or store any of this data on your behalf.</p>'
        },
        generatedLetterInfo: {
            postion: 'top',
            title: 'Information',
            description: '<p>A landlord must acknowledge a complaint at stage 1 of its process within 5 working days and supply a written response within 10 working days from the date of acknowledgment.</p><p><strong>Your landlord must respond to your complaint in writing - this will usually by email. If the landlord responds to your complaint by any other method, you should ask it to send its response to you in writing.</strong></p>'
        },
        inputFields: [
            {
                title: 'Address of property',
                short_descp: '<p>First line of address</p>',
                placeholderTxt: '123 street name',
                type: 'text',
                value: '',
                position: '1',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Postcode of property',
                short_descp: '<p>postcode.</p>',
                placeholderTxt: 'AB12 123',
                type: 'text',
                value: '',
                position: '2',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Name of landlord / housing association',
                short_descp: '',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '3',
                letterPosition: '1',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Address of landlord / housing association',
                short_descp: '',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '4',
                letterPosition: '2',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Postcode of landlord / housing association',
                short_descp: '',
                placeholderTxt: 'AB12 123',
                type: 'text',
                value: '',
                position: '5',
                letterPosition: '2',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Your name',
                short_descp: '<p>Or the name of the tenant if on someone else’s behalf.</p>',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '6',
                letterPosition: '8',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Your contact details',
                short_descp: '<p>Or the contact details of the tenant if writing on someone else’s behalf.</p>',
                placeholderTxt: 'Phone number or email address',
                type: 'text',
                value: '',
                position: '7',
                letterPosition: '10',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Date when problem first started',
                short_descp: '',
                placeholderTxt: '',
                type: 'date',
                value: '',
                position: '8',
                letterPosition: '2',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Date when you first reported it',
                short_descp: '',
                placeholderTxt: '',
                type: 'date',
                value: '',
                position: '9',
                letterPosition: '2',
                isValid: true,
                toolTip: '',
            },

            {
                title: 'The issue',
                short_descp: '<p>We recommend adding as much detail as possible</p>',
                placeholderTxt: '',
                type: 'ck-editor',
                value: '',
                position: '10',
                letterPosition: '4',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'What has gone wrong with the landlord\'s handling of it?',
                short_descp: '',
                placeholderTxt: 'E.g. I have received no response',
                type: 'ck-editor',
                value: '',
                position: '11',
                letterPosition: '4',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'How is it affecting you or your household?',
                short_descp: '',
                placeholderTxt: 'E.g. is making home unsafe to live in',
                type: 'ck-editor',
                value: '',
                position: '12',
                letterPosition: '4',
                isValid: true,
                toolTip: '',
            },

            {
                title: 'Let them know how they can put things right',
                short_descp: '<p><p>Examples</p><ul><li><p>an apology</p></li><li><p>an action plan for repairs or works to be carried out</p></li><li><p>financial compensation</p></li><li><p>an explanation for the time taken to carry our repairs/ provide a response</p></li></ul></p>',
                placeholderTxt: '',
                type: 'ck-editor',
                value: '',
                position: '13',
                letterPosition: '4',
                isValid: true,
                toolTip: '',
            },
        ],
        letterContaint: [
            {
                title: '',
                postion: 1,
                default: 'Name of landlord / housing association',
                valueOF: 'Name of landlord / housing association',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 2,
                default: 'Landlord / Letting Agent address',
                valueOF: 'Address of landlord / housing association',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 2,
                default: 'Landlord / Letting Agent Postcode',
                valueOF: 'Postcode of landlord / housing association',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 3,
                default: moment(new Date()).format("DD/MM/YYYY"),
                valueOF: 'Date',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 4,
                default: 'Name of landlord / letting agent',
                valueOF: 'Name of landlord / housing association',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: 'Dear ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'I am writing to make a complaint about the way you have handled my reports of:',
                position: 5,
                default: 'The issue',
                valueOF: 'The issue',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 6,
                default: 'Date when problem first started',
                valueOF: 'Date when problem first started',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: 'This first started on ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 7,
                default: 'Date when you first reported it',
                valueOF: 'Date when you first reported it',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: 'I originally reported it on ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'I am now making a complaint because',
                position: 8,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 9,
                default: 'What has gone wrong with the landlord\'s handling of the issue',
                valueOF: 'What has gone wrong with the landlord\'s handling of it?',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 10,
                default: 'How is it affecting you or your household',
                valueOF: 'How is it affecting you or your household?',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

            {
                title: 'To put things right, I think you should ',
                position: 11,
                default: 'Let them know how they can put things right',
                valueOF: 'Let them know how they can put things right',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 12,
                default: 'Your contact details',
                valueOF: 'Your contact details',
                alignment: 'side-right',
                leftdescription: 'Please confirm that you have received my complaint and when I can expect to receive a response. If you would like any more information to help you investigate my complaint, then please contact me on ',
                rightdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

            {
                title: 'Yours sincerely ',
                position: 13,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 14,
                default: 'Your name',
                valueOF: 'Your name',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'Tenant',
                position: 15,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 16,
                default: 'Address of property',
                valueOF: 'Address of property',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 16,
                default: 'Postcode',
                valueOF: 'Postcode of property',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 17,
                default: 'Your contact details',
                valueOF: 'Your contact details',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

        ]
    },
    escalateCouncilLandlordHA: {
        maxLeftIndex: 4,
        warning: false,
        description: '<p>This letter template can be used to escalate a complaint to a council landlord or housing association. Landlords must have a 2 stage complaint procedure. This is important because it gives you an opportunity to let your landlord know if you do not agree with the actions or decision made at stage 1 of the process.</p>',
        builderInfo: {
            title: 'We do not store any of this data or send anything on your behalf',
            description: '<p>The following information is used purely to generate a personalised letter based on our template file. We will never send or store any of this data on your behalf.</p>'
        },
        generatedLetterInfo: {
            postion: 'top',
            title: 'Information',
            description: '<p>Landlords must have a 2 stage complaint procedure. This is important because it gives you an opportunity to let your landlord know if you do not agree with the actions or decision made at stage 1 of the process.</p><p> A landlord must acknowledge a stage 2 complaint within 5 working days and supply a written response within 20 working days.</p>'
        },
        inputFields: [
            {
                title: 'Address of property',
                short_descp: '<p>First line of address</p>',
                placeholderTxt: '123 street name',
                type: 'text',
                value: '',
                position: '1',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Postcode of property',
                short_descp: '<p>Postcode.</p>',
                placeholderTxt: 'AB12 123',
                type: 'text',
                value: '',
                position: '2',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Name of landlord / housing association',
                short_descp: '',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '3',
                letterPosition: '1',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Address of landlord / housing association',
                short_descp: '',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '4',
                letterPosition: '2',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Postcode of landlord / housing association',
                short_descp: '',
                placeholderTxt: 'AB12 123',
                type: 'text',
                value: '',
                position: '5',
                letterPosition: '2',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Your name',
                short_descp: '<p>Or the name of the tenant if on someone else’s behalf.</p>',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '6',
                letterPosition: '8',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Your contact details',
                short_descp: '<p>Or the contact details of the tenant if writing on someone else’s behalf.</p>',
                placeholderTxt: 'Phone number or email address',
                type: 'text',
                value: '',
                position: '7',
                letterPosition: '10',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Your complaints reference number',
                short_descp: '',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '8',
                letterPosition: '2',
                isValid: true,
                toolTip: 'You can bring a complaint about your landlord to the Housing Ombudsman Service for investigation if you have completed your landlord’s complaint process and the issues have not been resolved or if your landlord is not responding to a complaint you have made to it.',
            },
            {
                title: 'Details of your complaint',
                short_descp: '<p>What went wrong with the landlord\'s handling of the issue?</p>',
                placeholderTxt: '',
                type: 'ck-editor',
                value: '',
                position: '9',
                letterPosition: '4',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Why are you not satisfied with the landlord’s response to your complaint?',
                short_descp: '<p><ul><li><p>Highlight any area in their letter that you disagree with and why<p></li><li><p>Any points your landlord failed to respond to</p></li></ul></p>',
                placeholderTxt: '',
                type: 'ck-editor',
                value: '',
                position: '10',
                letterPosition: '4',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'How is it affecting you or your household?',
                short_descp: '',
                placeholderTxt: '',
                type: 'ck-editor',
                value: '',
                position: '11',
                letterPosition: '4',
                isValid: true,
                toolTip: '',
            },

            {
                title: 'Let them know how they can put things right',
                short_descp: '',
                placeholderTxt: '<p><p>Examples</p><ul><li><p>an apology</p></li><li><p>an action plan for repairs or works to be carried out</p></li><li><p>financial compensation</p></li><li><p>an explanation for the time taken to carry our repairs/ provide a response</p></li></ul></p>',
                type: 'ck-editor',
                value: '',
                position: '12',
                letterPosition: '4',
                isValid: true,
                toolTip: '',
            },
        ],
        letterContaint: [
            {
                title: '',
                postion: 1,
                default: 'Name of landlord / housing association',
                valueOF: 'Name of landlord / housing association',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 2,
                default: 'Landlord / Letting Agent address',
                valueOF: 'Address of landlord / housing association',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 2,
                default: 'Landlord / Letting Agent postcode',
                valueOF: 'Postcode of landlord / housing association',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 3,
                default: moment(new Date()).format("DD/MM/YYYY"),
                valueOF: 'Date',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 4,
                default: 'Name of landlord / letting association',
                valueOF: 'Name of landlord / housing association',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: 'Dear ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 5,
                default: 'Complaints reference number',
                valueOF: 'Your complaints reference number',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: 'I am writing about my complaints reference ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 6,
                default: 'Details of your complaint',
                valueOF: 'Details of your complaint',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: 'I received your response to my complaint that you sent to me about my complaint regarding ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 7,
                default: 'How is the issue affecting you or your household',
                valueOF: 'How is it affecting you or your household?',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'I am not satisfied that the response you have given me has resolved my complaint. This is because of the following reasons:',
                position: 8,
                default: 'Why you are not satisfied with your landlord\'s handling of your complaint. ',
                valueOF: 'Why are you not satisfied with the landlord’s response to your complaint?',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 9,
                default: 'How they can put things right',
                valueOF: 'Let them know how they can put things right',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: 'To put things right, I think you should ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'I would now like to escalate my complaint to the next stage of your complaint procedure. Please can you let me know that you have received this request and when I can expect to receive a final response to my complaint. ',
                position: 10,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

            {
                title: 'I look forward to hearing from you. ',
                position: 11,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'Yours sincerely ',
                position: 12,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 13,
                default: 'Your name',
                valueOF: 'Your name',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'Tenant',
                position: 14,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 15,
                default: 'Address of property',
                valueOF: 'Address of property',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 15,
                default: 'Postcode of property',
                valueOF: 'Postcode of property',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 16,
                default: 'Your contact details',
                valueOF: 'Your contact details',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

        ]

    },
    followUPLandlord: {
        maxLeftIndex: 5,
        warning: false,
        description: '<p>This letter template can be used to escalate a complaint to a council landlord or housing association.</p><p><strong>Landlords must have a 2 stage complaint procedure. This is important because it gives you an opportunity to let your landlord know if you do not agree with the actions or decision made at stage 1 of the process.</strong></p>',
        builderInfo: {
            title: 'We do not store any of this data or send anything on your behalf',
            description: '<p>The following information is used purely to generate a personalised letter based on our template file. We will never send or store any of this data on your behalf.</p>'
        },
        generatedLetterInfo: {
            postion: 'top',
            title: 'Information',
            description: '<p>Landlords must have a 2 stage complaint procedure. This is important because it gives you an opportunity to let your landlord know if you do not agree with the actions or decision made at stage 1 of the process.</p><p>A landlord must acknowledge a stage 2 complaint within 5 working days and supply a written response within 20 working days.</p>'
        },
        inputFields: [
            {
                title: 'Address of property',
                short_descp: '<p>First line of address</p>',
                placeholderTxt: '123 street name',
                type: 'text',
                value: '',
                position: '1',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Postcode of property',
                short_descp: '<p>Postcode.</p>',
                placeholderTxt: 'AB12 123',
                type: 'text',
                value: '',
                position: '2',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Name of landlord',
                short_descp: '',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '3',
                letterPosition: '1',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Address of landlord',
                short_descp: '',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '4',
                letterPosition: '2',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Postcode of landlord',
                short_descp: '',
                placeholderTxt: 'AB12 123',
                type: 'text',
                value: '',
                position: '5',
                letterPosition: '2',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Your name',
                short_descp: '<p>Or the name of the tenant if on someone else’s behalf.</p>',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '6',
                letterPosition: '8',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Your contact details',
                short_descp: '<p>Or the contact details of the tenant if writing on someone else’s behalf.</p>',
                placeholderTxt: 'Phone number or email address',
                type: 'text',
                value: '',
                position: '7',
                letterPosition: '10',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Date when repair need first started',
                short_descp: '',
                placeholderTxt: '',
                type: 'date',
                value: '',
                position: '8',
                letterPosition: '2',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Date when you first reported it',
                short_descp: '',
                placeholderTxt: '',
                type: 'date',
                value: '',
                position: '9',
                letterPosition: '2',
                isValid: true,
                toolTip: '',
            },

            {
                title: 'The issue',
                short_descp: '',
                placeholderTxt: '',
                type: 'ck-editor',
                value: '',
                position: '10',
                letterPosition: '4',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'What has gone wrong with the landlord\'s response?',
                short_descp: '',
                placeholderTxt: 'e.g. lack of response',
                type: 'ck-editor',
                value: '',
                position: '11',
                letterPosition: '4',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'How is it affecting you or your household?',
                short_descp: '',
                placeholderTxt: 'E.g. is making home unsafe to live in',
                type: 'ck-editor',
                value: '',
                position: '12',
                letterPosition: '4',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'How can they make things right?',
                short_descp: '',
                placeholderTxt: 'e.g. an action plan for repairs or works to be carried out',
                type: 'ck-editor',
                value: '',
                position: '13',
                letterPosition: '4',
                isValid: true,
                toolTip: '',
            },

        ],
        letterContaint: [
            {
                title: 'Subject: Follow up request for repairs',
                postion: 0,
                default: '',
                valueOF: '',
                alignment: 'left',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                postion: 1,
                default: 'Name of landlord',
                valueOF: 'Name of landlord',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 2,
                default: 'Landlord address',
                valueOF: 'Address of landlord',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 2,
                default: 'Landlord postcode',
                valueOF: 'Postcode of landlord',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 3,
                default: moment(new Date()).format("DD/MM/YYYY"),
                valueOF: 'Date',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 4,
                default: 'Name of landlord',
                valueOF: 'Name of landlord',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: 'Dear ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 5,
                default: 'Address of property',
                valueOF: 'Address of property',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 5,
                default: 'Postcode of property',
                valueOF: 'Postcode of property',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'I am writing to make a follow up request for repair/maintenances on the above property.',
                position: 6,
                default: 'The issue',
                valueOF: 'The issue',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 7,
                default: 'Date when repair need first started',
                valueOF: 'Date when repair need first started',
                alignment: 'side-right',
                leftdescription: 'This first started ',
                rightdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 8,
                default: 'Date when you first reported it',
                valueOF: 'Date when you first reported it',
                alignment: 'side-right',
                leftdescription: 'I originally reported it on ',
                rightdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'However, it has not yet been fully addressed:',
                position: 9,
                default: 'What has gone wrong with the landlord\'s response',
                valueOF: 'What has gone wrong with the landlord\'s response?',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

            {
                title: '',
                position: 10,
                default: 'How is it affecting you/ your household',
                valueOF: 'How is it affecting you or your household?',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

            {
                title: '',
                position: 11,
                default: 'How can they make things right',
                valueOF: 'How can they make things right?',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 12,
                default: 'your contact details',
                valueOF: 'Your contact details',
                alignment: 'side-right',
                leftdescription: 'I have received information from the TDS Charitable Foundation “My Housing Issue Gateway”, and it is my understanding that you have an obligation to address these issues. Please contact me on ',
                rightdescription: 'to confirm how long you expect repairs to take and who will do the repair work.',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

            {
                title: 'Yours sincerely ',
                position: 13,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 14,
                default: 'Your name',
                valueOF: 'Your name',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'Tenant',
                position: 15,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 16,
                default: 'Your address',
                valueOF: 'Address of property',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 16,
                default: 'Your Postcode',
                valueOF: 'Postcode of property',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 17,
                default: 'Your contact details',
                valueOF: 'Your contact details',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

        ]
    },
    complaintLettingAgent: {
        maxLeftIndex: 5,
        warning: false,
        description: '<p>This letter template can be used to escalate a complaint to a council landlord or housing association. Landlords must have a 2 stage complaint procedure. This is important because it gives you an opportunity to let your landlord know if you do not agree with the actions or decision made at stage 1 of the process.</p>',
        builderInfo: {
            title: 'We do not store any of this data or send anything on your behalf',
            description: '<p>The following information is used purely to generate a personalised letter based on our template file. We will never send or store any of this data on your behalf.</p>'
        },
        generatedLetterInfo: {
            postion: 'top',
            title: 'Information',
            description: '<p>Landlords must have a 2 stage complaint procedure. This is important because it gives you an opportunity to let your landlord know if you do not agree with the actions or decision made at stage 1 of the process.</p><p> A landlord must acknowledge a stage 2 complaint within 5 working days and supply a written response within 20 working days.</p>'
        },
        inputFields: [
            {
                title: 'Address of property',
                short_descp: '<p>First line of address</p>',
                placeholderTxt: '123 street name',
                type: 'text',
                value: '',
                position: '1',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Postcode of property',
                short_descp: '<p>Postcode.</p>',
                placeholderTxt: 'AB12 123',
                type: 'text',
                value: '',
                position: '2',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Name of letting agent',
                short_descp: '',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '3',
                letterPosition: '1',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Address of letting agent',
                short_descp: '',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '4',
                letterPosition: '2',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Postcode of letting agent',
                short_descp: '',
                placeholderTxt: 'AB12 123',
                type: 'text',
                value: '',
                position: '5',
                letterPosition: '2',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Your name',
                short_descp: '<p>Or the name of the tenant if on someone else’s behalf.</p>',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '6',
                letterPosition: '8',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Your contact details',
                short_descp: '<p>Or the contact details of the tenant if writing on someone else’s behalf.</p>',
                placeholderTxt: 'Phone number or email address',
                type: 'text',
                value: '',
                position: '7',
                letterPosition: '10',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Date when repair need first started',
                short_descp: '',
                placeholderTxt: '',
                type: 'date',
                value: '',
                position: '8',
                letterPosition: '2',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Date you first reported the issue',
                short_descp: '',
                placeholderTxt: '',
                type: 'date',
                value: '',
                position: '9',
                letterPosition: '2',
                isValid: true,
                toolTip: '',
            },

            {
                title: 'The issue',
                short_descp: '<p>We recommend giving as much detail as possible</p>',
                placeholderTxt: '',
                type: 'ck-editor',
                value: '',
                position: '10',
                letterPosition: '4',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'What has gone wrong with the letting agent\'s response?',
                short_descp: '',
                placeholderTxt: 'e.g. lack of response',
                type: 'ck-editor',
                value: '',
                position: '11',
                letterPosition: '4',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'How is it affecting you / your household?',
                short_descp: '',
                placeholderTxt: 'e.g. is making home unsafe to live in',
                type: 'ck-editor',
                value: '',
                position: '12',
                letterPosition: '4',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'How can they make things right?',
                short_descp: '',
                placeholderTxt: 'e.g. an action plan for repairs or works to be carried out',
                type: 'ck-editor',
                value: '',
                position: '13',
                letterPosition: '4',
                isValid: true,
                toolTip: '',
            },

        ],
        letterContaint: [
            {
                title: 'Subject: Complaint regarding repairs / maintenance',
                postion: 0,
                default: '',
                valueOF: '',
                alignment: 'left',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                postion: 1,
                default: 'Name of letting agent',
                valueOF: 'Name of letting agent',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 2,
                default: 'Letting Agent address',
                valueOF: 'Address of letting agent',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 2,
                default: 'Letting Agent postcode',
                valueOF: 'Postcode of letting agent',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 3,
                default: moment(new Date()).format("DD/MM/YYYY"),
                valueOF: 'Date',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 4,
                default: 'Name of letting agent',
                valueOF: 'Name of letting agent',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: 'Dear ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 5,
                default: 'Address of property',
                valueOF: 'Address of property',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 5,
                default: 'Postcode of property',
                valueOF: 'Postcode of property',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'I am writing to make a complaint following my request for repair/ maintenance on the property.',
                position: 6,
                default: 'The issue',
                valueOF: 'The issue',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 7,
                default: 'Date when repair need first started',
                valueOF: 'Date when repair need first started',
                alignment: 'side-right',
                leftdescription: 'This first started ',
                rightdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 8,
                default: 'Date you first reported the issue',
                valueOF: 'Date you first reported the issue',
                alignment: 'side-right',
                leftdescription: 'I originally reported it on ',
                rightdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'However, it has not yet been fully addressed:',
                position: 9,
                default: 'What has gone wrong with letting agent\'s response',
                valueOF: 'What has gone wrong with the letting agent\'s response?',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

            {
                title: '',
                position: 10,
                default: 'How is it affecting you / your household',
                valueOF: 'How is it affecting you / your household?',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

            {
                title: '',
                position: 11,
                default: 'How can they make things right',
                valueOF: 'How can they make things right?',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 12,
                default: 'your telephone number or email address',
                valueOF: 'Your contact details',
                alignment: 'center',
                leftdescription: 'I have received information from the TDS Charitable Foundation “My Housing Issue Gateway”, and it is my understanding that you have an obligation to address these issues. Please contact me on ',
                rightdescription: ' to confirm how long you expect repairs to take and who will do the repair work.',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'Please also send me details of your complaints handling procedure.',
                position: 13,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'Yours sincerely',
                position: 14,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 15,
                default: 'Your name',
                valueOF: 'Your name',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'Tenant',
                position: 16,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 17,
                default: 'Your address',
                valueOF: 'Address of property',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 17,
                default: 'Your postcode',
                valueOF: 'Postcode of property',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 18,
                default: 'Your contact details',
                valueOF: 'Your contact details',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
        ]
    },
    tel_followUpLandlord: {
        warning: false,
        inputFields: [
            {
                label: 'Introduce yourself',
                description: 'Provide your name and address of the property you are renting.',
                isValid: true,
                type: 'ck-editor',
                _className: 'col-12 col-md-8',
                selected: false,
                value: '',
                placeholder: 'Use the notes box to remind yourself of any key points you want to say, as well as their response',
                id: 1
            },
            {
                label: 'Briefly explain the reason for your call',
                description: 'To make a follow up request for repair / maintenance',
                isValid: true,
                type: 'ck-editor',
                _className: 'col-12 col-md-8',
                selected: false,
                value: '',
                placeholder: 'Use the notes box to remind yourself of any key points you want to say, as well as their response',
                id: 2
            },


            {
                label: 'Give details of the issue',
                description: '',
                isValid: true,
                type: 'ck-editor',
                selected: false,
                _className: 'col-12 col-md-8',
                value: '',
                placeholder: '',
                id: 3
            },
            {
                label: 'Date when repair need first started',
                description: '',
                isValid: true,
                type: 'date',
                selected: false,
                _className: 'col-12 col-md-8',
                value: '',
                placeholder: '',
                id: 4
            },
            {
                label: 'Provide date when you first reported the issue',
                description: '',
                isValid: true,
                type: 'date',
                selected: false,
                _className: 'col-12 col-md-8',
                value: '',
                placeholder: '',
                id: 5
            },
            {
                label: 'Ask',
                description: 'Ask if they have a record of it being reported',
                isValid: true,
                type: 'ck-editor',
                selected: false,
                _className: 'col-12 col-md-8',
                value: '',
                placeholder: 'Use the notes box to remind yourself of any key points you want to say, as well as their response',
                id: 6
            },
            {
                label: 'Explain how it\'s affecting you',
                description: '',
                isValid: true,
                type: 'ck-editor',
                selected: false,
                _className: 'col-12 col-md-8',
                value: '',
                placeholder: 'e.g. making the house unsafe to live in',
                id: 7
            },
            {
                label: 'Tell them how they can put it right',
                description: '',
                isValid: true,
                type: 'ck-editor',
                selected: false,
                _className: 'col-12 col-md-8',
                value: '',
                placeholder: 'e.g. carry out the repair works',
                id: 8
            },
            {
                label: 'Ask',
                description: 'What are their next steps and when you can expect to hear back',
                isValid: true,
                type: 'ck-editor',
                selected: false,
                _className: 'col-12 col-md-8',
                value: '',
                placeholder: 'Use the notes box to remind yourself of any key points you want to say, as well as their response',
                id: 9
            },
        ]
    },
    contactCouncilFailedLandlordLettingAgent: {
        maxLeftIndex: 2,
        warning: false,
        description: '<p>This letter template can be used to address the council if your landlord or letting agent failed to address the issue.</p>',
        builderInfo: {
            title: 'We do not store any of this data or send anything on your behalf',
            description: '<p>The following information is used purely to generate a personalised letter based on our template file. We will never send or store any of this data on your behalf.</p>'
        },
        generatedLetterInfo: {
            postion: 'top',
            title: 'Information',
            description: '<p>Landlords must have a 2 stage complaint procedure. This is important because it gives you an opportunity to let your landlord know if you do not agree with the actions or decision made at stage 1 of the process.</p><p> A landlord must acknowledge a stage 2 complaint within 5 working days and supply a written response within 20 working days.</p>'
        },
        inputFields: [
            {
                title: 'Address of property',
                short_descp: '<p>First line of address</p>',
                placeholderTxt: '123 street name',
                type: 'text',
                value: '',
                position: '1',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Postcode of property',
                short_descp: '<p>postcode.</p>',
                placeholderTxt: 'AB12 123',
                type: 'text',
                value: '',
                position: '2',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Name of landlord / letting agent',
                short_descp: '',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '3',
                letterPosition: '1',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Your name',
                short_descp: '<p>Or the name of the tenant if on someone else’s behalf.</p>',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '4',
                letterPosition: '8',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Your contact details',
                short_descp: '<p>Or the contact details of the tenant if writing on someone else’s behalf.</p>',
                placeholderTxt: 'Phone number or email address',
                type: 'text',
                value: '',
                position: '5',
                letterPosition: '10',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Date when issue first started',
                short_descp: '',
                placeholderTxt: '',
                type: 'date',
                value: '',
                position: '6',
                letterPosition: '2',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Date you first reported the issue',
                short_descp: '',
                placeholderTxt: '',
                type: 'date',
                value: '',
                position: '7',
                letterPosition: '2',
                isValid: true,
                toolTip: '',
            },

            {
                title: 'The issue',
                short_descp: 'We recommend adding as much detail as possible',
                placeholderTxt: '',
                type: 'ck-editor',
                value: '',
                position: '8',
                letterPosition: '4',
                isValid: true,
                toolTip: '',
            },

            {
                title: 'How is it affecting you / your household?',
                short_descp: 'E.g. it is making your home unsafe to live in',
                placeholderTxt: '',
                type: 'ck-editor',
                value: '',
                position: '9',
                letterPosition: '4',
                isValid: true,
                toolTip: '',
            },
        ],
        letterContaint: [
            {
                title: 'Subject: Request for inspection under the Housing, Health and Safety Rating System (HHSRS)',
                postion: 1,
                default: '',
                valueOF: '',
                alignment: 'left',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 2,
                default: moment(new Date()).format("DD/MM/YYYY"),
                valueOF: 'Date',
                alignment: 'normal',
                leftdescription: '',
                rightdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'Dear Sir/ Madam',
                position: 3,
                default: '',
                valueOF: '',
                alignment: 'normal',
                leftdescription: '',
                rightdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'Regarding property: ',
                position: 4,
                default: '',
                valueOF: '',
                alignment: 'normal',
                leftdescription: '',
                rightdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 4,
                default: 'Address of property',
                valueOF: 'Address of property',
                alignment: 'side-right',
                leftdescription: '',
                rightdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 4,
                default: 'Postcode of property',
                valueOF: 'Postcode of property',
                alignment: 'side-right',
                leftdescription: '',
                rightdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 5,
                default: 'Name of landlord / letting agent',
                valueOF: 'Name of landlord / letting agent',
                alignment: 'center',
                leftdescription: 'I am writing regarding an issue with my property which the landlord / letting agent - ',
                rightdescription: '- has failed to address.',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 6,
                default: 'Date when issue first started',
                valueOF: 'Date when issue first started',
                alignment: 'side-right',
                leftdescription: 'The issue first started on ',
                rightdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 7,
                default: 'Date you first reported the issue',
                valueOF: 'Date you first reported the issue',
                alignment: 'side-right',
                leftdescription: 'I first reported the issue on ',
                rightdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 8,
                default: 'The issue',
                valueOF: 'The issue',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 9,
                default: 'How is it affecting you / your household',
                valueOF: 'How is it affecting you / your household?',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'I would like to ask for your assistance to ensure that the landlord carried out the needed repairs.',
                position: 10,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 11,
                default: 'Your contact details',
                valueOF: 'Your contact details',
                alignment: 'side-right',
                leftdescription: 'Please contact me on ',
                rightdescription: ' to discuss next steps and, if necessary, to arrange an inspection under the Housing, Health and Safety Rating System (HHSRS).',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

            {
                title: 'Yours sincerely ',
                position: 12,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 13,
                default: 'Your name',
                valueOF: 'Your name',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'Tenant',
                position: 14,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 15,
                default: 'Your address',
                valueOF: 'Address of property',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 15,
                default: 'Your postcode',
                valueOF: 'Postcode of property',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 16,
                default: 'Your contact details',
                valueOF: 'Your contact details',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

        ]
    },
    tel_contactCouncilFailedLandlordLettingAgent: {
        warning: false,
        inputFields: [
            {
                label: 'Introduce yourself',
                description: 'Provide your name and address of the property you are renting.',
                isValid: true,
                type: 'ck-editor',
                _className: 'col-12 col-md-8',
                selected: false,
                value: '',
                placeholder: 'Use the notes box to remind yourself of any key points you want to say, as well as their response',
                id: 1
            },
            {
                label: 'Briefly explain the reason for your call',
                description: 'About an issue with your property, which the landlord / letting agent has failed to address',
                isValid: true,
                type: 'ck-editor',
                _className: 'col-12 col-md-8',
                selected: false,
                value: '',
                placeholder: 'Use the notes box to remind yourself of any key points you want to say, as well as their response',
                id: 2
            },


            {
                label: 'Give details of the issue',
                description: 'We\'ve started it based on what you told us - but we recommend adding as much detail as possible.',
                isValid: true,
                type: 'ck-editor',
                selected: false,
                _className: 'col-12 col-md-8',
                value: '',
                placeholder: '',
                id: 3
            },
            {
                label: 'Date when it first started',
                description: '',
                isValid: true,
                type: 'date',
                selected: false,
                _className: 'col-12 col-md-8',
                value: '',
                placeholder: '',
                id: 4
            },
            {
                label: 'Provide date when you first reported the issue',
                description: '',
                isValid: true,
                type: 'date',
                selected: false,
                _className: 'col-12 col-md-8',
                value: '',
                placeholder: '',
                id: 5
            },

            {
                label: 'Explain how it\'s affecting you',
                description: '',
                isValid: true,
                type: 'ck-editor',
                selected: false,
                _className: 'col-12 col-md-8',
                value: '',
                placeholder: '',
                id: 6
            },
            {
                label: 'Make a request',
                description: 'We have made a suggestion below. ',
                isValid: true,
                type: 'ck-editor',
                selected: false,
                _className: 'col-12 col-md-8',
                value: '',
                placeholder: 'I would like to ask for your assistance to ensure that the landlord carries out the needed repairs.',
                id: 7
            },
            {
                label: 'Say',
                description: 'We have made a suggestion below.',
                isValid: true,
                type: 'ck-editor',
                selected: false,
                _className: 'col-12 col-md-8',
                value: '',
                placeholder: '',
                id: 9
            },
            {
                label: 'Ask',
                description: '',
                isValid: true,
                type: 'ck-editor',
                selected: false,
                _className: 'col-12 col-md-8',
                value: '',
                placeholder: '',
                id: 9
            },
        ]
    },
    councilEvictionNotice: {
        maxLeftIndex: 2,
        warning: false,
        description: '<p>This letter template can be used to address the council if an eviction notice has been received</p>',
        builderInfo: {
            title: 'We do not store any of this data or send anything on your behalf',
            description: '<p>The following information is used purely to generate a personalised letter based on our template file. We will never send or store any of this data on your behalf.</p>'
        },
        generatedLetterInfo: {
            postion: 'top',
            title: '',
            description: ''
        },
        inputFields: [
            {
                title: 'Address of property',
                short_descp: '<p>First line of address</p>',
                placeholderTxt: '123 street name',
                type: 'text',
                value: '',
                position: '1',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Postcode of property',
                short_descp: '<p>postcode.</p>',
                placeholderTxt: 'AB12 123',
                type: 'text',
                value: '',
                position: '2',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Name of landlord / letting agent',
                short_descp: '',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '3',
                letterPosition: '1',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Your name',
                short_descp: '<p>Or the name of the tenant if on someone else’s behalf.</p>',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '4',
                letterPosition: '8',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Your contact details',
                short_descp: '<p>Or the contact details of the tenant if writing on someone else’s behalf.</p>',
                placeholderTxt: 'Phone number or email address',
                type: 'text',
                value: '',
                position: '5',
                letterPosition: '10',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Date the eviction notice was received',
                short_descp: '',
                placeholderTxt: '',
                type: 'date',
                value: '',
                position: '6',
                letterPosition: '1',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Names, ages and relationships of people who normally live with you',
                short_descp: 'Or the names, ages and relationships of people who normally live with the tenant if writing on someone else\'s behalf. ',
                placeholderTxt: '',
                type: 'ck-editor',
                value: '',
                position: '7',
                letterPosition: '4',
                isValid: true,
                toolTip: '',
            },

        ],
        letterContaint: [
            {
                title: '',
                postion: 1,
                default: moment(new Date()).format("DD/MM/YYYY"),
                valueOF: 'Date',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'Dear Homelessness Team,',
                position: 2,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 3,
                default: 'Your name',
                valueOF: 'Your name',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: 'My name is ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 4,
                default: 'Names, ages and relationships of people who normally live with you',
                valueOF: 'Names, ages and relationships of people who normally live with you',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: 'I live with ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 5,
                default: 'Date eviction notice was received',
                valueOF: 'Date the eviction notice was received',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: 'I have received an eviction notice from my landlord / letting agent on ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'I need help under Part 7 of the Housing Act 1996. Please treat this as my homelessness application.',
                position: 6,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'I am asking for help with finding accommodation. I have given you reason to believe that I may be homeless or threatened with homelessness (Homelessness code of guidance, para 18.5). ',
                position: 7,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'I need to speak to a homeless officer urgently. Please do not direct me to an online form as I find this difficult. By law, you cannot insist I make my homeless application in a particular way.',
                position: 8,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 9,
                default: 'Your contact details',
                valueOF: 'Your contact details',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: 'Please contact me on ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

            {
                title: 'Yours sincerely ',
                position: 10,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 11,
                default: 'Your name',
                valueOF: 'Your name',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 12,
                default: 'Your address',
                valueOF: 'Address of property',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 12,
                default: 'Your postcode',
                valueOF: 'Postcode of property',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 13,
                default: 'Your contact details',
                valueOF: 'Your contact details',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

        ]
    },
    tel_councilEvictionNotice: {
        warning: false,
        inputFields: [
            {
                label: 'Introduce yourself',
                description: 'Provide your name and address of the property you are renting.',
                isValid: true,
                type: 'ck-editor',
                _className: 'col-12 col-md-8',
                selected: false,
                value: '',
                placeholder: 'Use the notes box to remind yourself of any key points you want to say, as well as their response',
                id: 1
            },
            {
                label: 'Briefly explain the reason for your call',
                description: 'About an issue with your property, which the landlord / letting agent has failed to address',
                isValid: true,
                type: 'ck-editor',
                _className: 'col-12 col-md-8',
                selected: false,
                value: '',
                placeholder: 'Use the notes box to remind yourself of any key points you want to say, as well as their response',
                id: 2
            },


            {
                label: 'Tell the council who you live with - names, ages, relationship to you',
                description: 'We\'ve started it based on what you told us - but we recommend adding as much detail as possible.',
                isValid: true,
                type: 'ck-editor',
                selected: false,
                _className: 'col-12 col-md-8',
                value: '',
                placeholder: 'Use the notes box to remind yourself of any key points you want to say, as well as their response',
                id: 3
            },
            {
                label: 'Say',
                description: '',
                isValid: true,
                type: 'ck-editor',
                selected: false,
                _className: 'col-12 col-md-8',
                value: '',
                placeholder: 'I believe that I will become homeless. I need help under Part 7 of the Housing Act 1996. Please treat this as a homelessness application. ',
                id: 4
            },
            {
                label: 'Make a request',
                description: 'We have made a suggestion below. ',
                isValid: true,
                type: 'ck-editor',
                selected: false,
                _className: 'col-12 col-md-8',
                value: '',
                placeholder: 'I am asking for help with finding accomodation.',
                id: 5
            },

            {
                label: 'Ask',
                description: '',
                isValid: true,
                type: 'ck-editor',
                selected: false,
                _className: 'col-12 col-md-8',
                value: '',
                placeholder: 'What are their next steps and when you can expect to hear back',
                id: 6
            },
        ]
    },
    landlordRentArrears: {
        maxLeftIndex: 1,
        warning: false,
        description: '<p>This letter template can be used to address the landlord about rent arrears (landlord / letting agent / council/ housing association)</p>',
        builderInfo: {
            title: 'We do not store any of this data or send anything on your behalf',
            description: '<p>The following information is used purely to generate a personalised letter based on our template file. We will never send or store any of this data on your behalf.</p>'
        },
        generatedLetterInfo: {
            postion: 'top',
            title: '',
            description: ''
        },
        inputFields: [
            {
                title: 'Address of property',
                short_descp: '<p>First line of address</p>',
                placeholderTxt: '123 street name',
                type: 'text',
                value: '',
                position: '1',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Postcode of property',
                short_descp: '<p>Postcode.</p>',
                placeholderTxt: 'AB12 123',
                type: 'text',
                value: '',
                position: '2',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Name of landlord / letting agent / housing association',
                short_descp: 'If you don\'t know their name or you are not addressing a specific person, put \'Sir / Madam\'',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '3',
                letterPosition: '1',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Address of landlord / letting agent / council landlord / housing association',
                short_descp: '',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '4',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Postcode of landlord / letting agent / council landlord / housing association',
                short_descp: '',
                placeholderTxt: 'AB12 123',
                type: 'text',
                value: '',
                position: '5',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Your name',
                short_descp: '<p>Or the name of the tenant if on someone else’s behalf.</p>',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '6',
                letterPosition: '8',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Your contact details',
                short_descp: '<p>Or the contact details of the tenant if writing on someone else’s behalf.</p>',
                placeholderTxt: 'Phone number or email address',
                type: 'text',
                value: '',
                position: '7',
                letterPosition: '10',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Actions you will immediately take in order to resolve your rent arrears',
                short_descp: '',
                placeholderTxt: '<p>Examples <ul><li>Apply for a ‘Discretionary Housing Payment’ to assist me in covering all or some of the additional rent I am struggling to pay.</li>\<li>I have reviewed all of my expenses and worked out what I can now afford to pay off my arrears and would like to talk to you about this so we can arrange a repayment plan.</li><li>I am talking to my relatives and friends to see if they can help me.</li><li>I am determined not to get into arrears again so I will be seeking advice from Citizens Advice</li><li>I will be engaging with the job centre to help me find work/ asking to increase my work hours to maximise my earnings.</li></ul></p>',
                type: 'ck-editor',
                value: '',
                position: '8',
                letterPosition: '4',
                isValid: true,
                toolTip: '',
            },

        ],
        letterContaint: [
            {
                title: '',
                postion: 1,
                default: moment(new Date()).format("DD/MM/YYYY"),
                valueOF: 'Date',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 2,
                default: 'Name of landlord / letting agent / housing association',
                valueOF: 'Name of landlord / letting agent / housing association',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: 'Dear ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                postion: 3,
                default: 'Address of property',
                valueOF: 'Address of property',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                postion: 3,
                default: 'Postcode of property',
                valueOF: 'Postcode of property',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'I am writing to you because you have asked me to leave my home at the above address due to the rent arrears I have accrued. I genuinely want to do everything I can to resolve this situation.',
                position: 4,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'Firstly, I would like to apologise for getting into rent arrears and the inconvenience this must have caused you. I promise you that I am working on trying to resolve my financial situation so I am in a position to repay you the money owed and ensure this doesn’t happen again. ',
                position: 5,
                default: '',
                valueOF: '',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: 'I live with',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'Secondly, I would be grateful if you were willing to consider the actions I am taking to resolve the matter and reconsider letting me stay in your property: ',
                position: 6,
                default: 'Actions you will immediately take in order to resolve your rent arrears',
                valueOF: 'Actions you will immediately take in order to resolve your rent arrears',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

            {
                title: 'I hope you can see that I am taking this matter seriously and I am exploring all options to sort out the arrears I owe as soon as possible. I would be grateful if you would contact me as soon as possible to discuss my offer in more detail. I really want to sort this out as quickly as possible. ',
                position: 8,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 9,
                default: 'Your contact details',
                valueOF: 'Your contact details',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: 'Please contact me on ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

            {
                title: 'Yours sincerely ',
                position: 10,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 11,
                default: 'Your name',
                valueOF: 'Your name',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 12,
                default: 'Your address',
                valueOF: 'Address of property',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 12,
                default: 'Your postcode',
                valueOF: 'Postcode of property',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 13,
                default: 'Your contact details',
                valueOF: 'Your contact details',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

        ]
    },
    councilReportHarassment: {
        maxLeftIndex: 1,
        warning: true,
        description: '<p>This letter template can be used to write to your council to report harassment from your landlord or letting agent.</p>',
        builderInfo: {
            title: 'We do not store any of this data or send anything on your behalf',
            description: '<p>The following information is used purely to generate a personalised letter based on our template file. We will never send or store any of this data on your behalf.</p>'
        },
        generatedLetterInfo: {
            postion: 'top',
            title: '',
            description: ''
        },
        warningInfo: {
            position: 'top',
            title: 'If you are in immediate danger you should contact the police on 999.'
        },
        inputFields: [
            {
                title: 'Address of property',
                short_descp: '<p>First line of address</p>',
                placeholderTxt: '123 street name',
                type: 'text',
                value: '',
                position: '1',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Postcode of property',
                short_descp: '<p>postcode.</p>',
                placeholderTxt: 'AB12 123',
                type: 'text',
                value: '',
                position: '2',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'I am writing to my:',
                short_descp: '',
                placeholderTxt: '',
                type: 'checkbox',
                value: '',
                position: '3',
                option: [
                    { id: '1', optionvalue: 'Landlord', selected: false },
                    { id: '2', optionvalue: 'Letting agent', selected: false },
                ],
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Name of landlord / letting agent',
                short_descp: '',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '4',
                letterPosition: '1',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Address of landlord / letting agent',
                short_descp: '',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '5',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Postcode of landlord / letting agent',
                short_descp: '',
                placeholderTxt: 'AB12 123',
                type: 'text',
                value: '',
                position: '6',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },

            {
                title: 'Your name',
                short_descp: '<p>Or the name of the tenant if on someone else’s behalf.</p>',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '7',
                letterPosition: '8',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Your contact details',
                short_descp: '<p>Or the contact details of the tenant if writing on someone else’s behalf.</p>',
                placeholderTxt: 'Phone number or email address',
                type: 'text',
                value: '',
                position: '8',
                letterPosition: '10',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Details of the harassment from landlord',
                short_descp: '<p>Include dates and times if possible</p>',
                placeholderTxt: '',
                type: 'ck-editor',
                value: '',
                position: '9',
                letterPosition: '10',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Explain how this is affecting you',
                short_descp: '',
                placeholderTxt: '',
                type: 'ck-editor',
                value: '',
                position: '10',
                letterPosition: '10',
                isValid: true,
                toolTip: '',
            },
        ],
        letterContaint: [
            {
                title: '',
                postion: 1,
                default: moment(new Date()).format("DD/MM/YYYY"),
                valueOF: 'Date',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'Dear Council Team,',
                position: 2,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                postion: 3,
                default: 'your name',
                valueOF: 'Your name',
                alignment: 'center',
                rightdescription: ' I live at,',
                leftdescription: 'My name is ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

            {
                title: '',
                postion: 3,
                default: 'Address of property',
                valueOF: 'Address of property',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: ' ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                postion: 3,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: ' ',
                default1: 'Postcode of property',
                valueOF1: 'Postcode of property',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 4,
                default: 'landlord / letting agent (tick box)',
                valueOF: 'I am writing to my:',
                alignment: 'side-right',
                rightdescription: ' is ',
                leftdescription: 'My ',
                default1: 'Name of landlord / letting agent',
                valueOF1: 'Name of landlord / letting agent',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'Their address is',
                position: 5,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: 'Their address is ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 5,
                default: 'Address of landlord / letting agent',
                valueOF: 'Address of landlord / letting agent',
                alignment: '',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 5,
                default: 'Postcode of landlord / letting agent',
                valueOF: 'Postcode of landlord / letting agent',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 6,
                default: 'landlord / letting agent (tick box)',
                valueOF: 'I am writing to my:',
                alignment: 'side-right',
                rightdescription: ' which I urgently need you to address. I have received information from the TDS Charitable Foundation “My Housing Issue Gateway”, and I understand that the Protection from Eviction Act 1977 (amended by Housing Act 1988) makes it a criminal offence to harass or unlawfully evict a residential occupier.',
                leftdescription: 'I have been experiencing harassment from my ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 7,
                default: 'details of the harassment from landlord',
                valueOF: 'Details of the harassment from landlord',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: 'Details of what has been occurring include ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 8,
                default: 'Explain how this is affecting you',
                valueOF: 'Explain how this is affecting you',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: 'This is how it\'s affecting me/my household ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 9,
                default: 'Your contact details',
                valueOF: 'Your contact details',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: 'Please can someone from the private rented housing team contact me as soon as possible on ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

            {
                title: 'Yours sincerely',
                position: 10,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 11,
                default: 'Your name',
                valueOF: 'Your name',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 12,
                default: 'Your address',
                valueOF: 'Address of property',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 12,
                default: 'Your postcode',
                valueOF: 'Postcode of property',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 13,
                default: 'Your contact details',
                valueOF: 'Your contact details',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

        ]
    },
    tel_councilReportHarassment: {
        warning: true,
        inputFields: [
            {
                label: 'Introduce yourself',
                description: 'Provide your name and address of the property you are renting.',
                isValid: true,
                type: 'ck-editor',
                _className: 'col-12 col-md-8',
                selected: false,
                value: '',
                placeholder: 'Use the notes box to remind yourself of any key points you want to say, as well as their response',
                id: 1
            },
            {
                label: 'Briefly explain the reason for your call',
                description: '',
                isValid: true,
                type: 'ck-editor',
                _className: 'col-12 col-md-8',
                selected: false,
                value: '',
                placeholder: 'I am being harassed by my landlord / letting agent',
                id: 2
            },
            {
                label: 'Name of landlord / letting agent',
                description: '',
                type: 'text',
                selected: false,
                _className: 'col-12 col-md-8',
                value: '',
                placeholder: '',
                id: 3
            },
            {
                label: 'Provide date when it first started',
                description: '',
                type: 'date',
                selected: false,
                _className: 'col-12 col-md-8',
                value: '',
                placeholder: '',
                id: 4
            },

            {
                label: 'Give details of the harassment',
                description: '',
                type: 'ck-editor',
                selected: false,
                _className: 'col-12 col-md-8',
                value: '',
                placeholder: '',
                id: 5
            },
            {
                label: 'Make a request',
                description: 'We have made a suggestion below. ',
                isValid: true,
                type: 'ck-editor',
                selected: false,
                _className: 'col-12 col-md-8',
                value: '',
                placeholder: 'For help to stop the harassment.',
                id: 7
            },
            {
                label: 'Ask',
                description: '',
                isValid: true,
                type: 'ck-editor',
                selected: false,
                _className: 'col-12 col-md-8',
                value: '',
                placeholder: 'What are their next steps and when you can expect to hear back',
                id: 8
            },
        ]
    },
    letterLandlordLettingAgent: {
        maxLeftIndex: 3,
        warning: false,
        description: '<p>This letter template can be used to write to your landlord / letting agent about refunding your deposit.</p>',
        builderInfo: {
            title: 'We do not store any of this data or send anything on your behalf',
            description: '<p>The following information is used purely to generate a personalised letter based on our template file. We will never send or store any of this data on your behalf.</p>'
        },
        generatedLetterInfo: {
            postion: 'bottom',
            title: '',
            description: ''
        },
        inputFields: [
            {
                title: 'Address of property',
                short_descp: '<p>First line of address</p>',
                placeholderTxt: '123 street name',
                type: 'text',
                value: '',
                position: '1',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Postcode of property',
                short_descp: '<p>Postcode.</p>',
                placeholderTxt: 'AB12 123',
                type: 'text',
                value: '',
                position: '2',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Name of landlord / letting agent',
                short_descp: '',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '3',
                letterPosition: '1',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Address of landlord / letting agent',
                short_descp: '',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '4',
                letterPosition: '2',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Postcode of landlord / letting agent',
                short_descp: '',
                placeholderTxt: 'AB12 123',
                type: 'text',
                value: '',
                position: '5',
                letterPosition: '2',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Your name',
                short_descp: '<p>Or the name of the tenant if on someone else’s behalf.</p>',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '6',
                letterPosition: '8',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Your contact details',
                short_descp: '<p>Or the contact details of the tenant if writing on someone else’s behalf.</p>',
                placeholderTxt: 'Phone number or email address',
                type: 'text',
                value: '',
                position: '7',
                letterPosition: '10',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Date the tenancy ended',
                short_descp: '',
                placeholderTxt: '',
                type: 'date',
                value: '',
                position: '8',
                letterPosition: '4',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Amount of the deposit',
                short_descp: '',
                placeholderTxt: '',
                type: 'number',
                value: '',
                position: '9',
                letterPosition: '4',
                isValid: true,
                toolTip: '',
            },

        ],
        letterContaint: [
            {
                title: '',
                position: 1,
                default: 'Address of landlord / letting agent',
                valueOF: 'Address of landlord / letting agent',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 1,
                default: 'Postcode of landlord / letting agent',
                valueOF: 'Postcode of landlord / letting agent',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 2,
                default: moment(new Date()).format("DD/MM/YYYY"),
                valueOF: 'Date',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 3,
                default: 'Name of landlord / letting agent',
                valueOF: 'Name of landlord / letting agent',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: 'Dear ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'I\'m the former tenant of ',
                position: 4,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 4,
                default: 'Address of property',
                valueOF: 'Address of property',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 4,
                default: 'Postcode of property',
                valueOF: 'Postcode of property',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'I\'m contacting you about the refund of a tenancy deposit.',
                position: 5,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 6,
                default: 'Date the tenancy ended',
                valueOF: 'Date the tenancy ended',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: 'My tenancy ended on ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'The property has been left in good order and the rent was fully paid.',
                position: 7,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 8,
                default: 'Amount of the deposit',
                valueOF: 'Amount of the deposit',
                alignment: 'center',
                rightdescription: ' within 10 days of receiving this letter.',
                leftdescription: 'Please refund the full ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'Yours sincerely',
                position: 9,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 10,
                default: 'Your name',
                valueOF: 'Your name',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'Tenant',
                position: 11,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 12,
                default: 'Your address',
                valueOF: 'Address of property',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 12,
                default: 'Your postcode',
                valueOF: 'Postcode of property',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 13,
                default: 'Your contact details',
                valueOF: 'Your contact details',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

        ]
    },
    letterIllegalFees: {
        maxLeftIndex: 4,
        warning: false,
        description: '<p>This letter template can be used to write to your landlord / letting agent about illegal fees.</p>',
        builderInfo: {
            title: 'We do not store any of this data or send anything on your behalf',
            description: '<p>The following information is used purely to generate a personalised letter based on our template file. We will never send or store any of this data on your behalf.</p>'
        },
        generatedLetterInfo: {
            postion: 'top',
            title: '',
            description: ''
        },
        inputFields: [
            {
                title: 'Address of property',
                short_descp: '<p>First line of address</p>',
                placeholderTxt: '123 street name',
                type: 'text',
                value: '',
                position: '1',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Postcode of property',
                short_descp: '<p>Postcode.</p>',
                placeholderTxt: 'AB12 123',
                type: 'text',
                value: '',
                position: '2',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Name of landlord / letting agent',
                short_descp: '',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '3',
                letterPosition: '1',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Address of landlord / letting agent',
                short_descp: '',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '4',
                letterPosition: '2',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Postcode of landlord / letting agent',
                short_descp: '',
                placeholderTxt: 'AB12 123',
                type: 'text',
                value: '',
                position: '5',
                letterPosition: '2',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Your name',
                short_descp: '<p>Or the name of the tenant if on someone else’s behalf.</p>',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '6',
                letterPosition: '8',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Your contact details',
                short_descp: '<p>Or the contact details of the tenant if writing on someone else’s behalf.</p>',
                placeholderTxt: 'Phone number or email address',
                type: 'text',
                value: '',
                position: '7',
                letterPosition: '10',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Amount charged',
                short_descp: '',
                placeholderTxt: '',
                type: 'number',
                value: '',
                position: '8',
                letterPosition: '4',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Type of charge',
                short_descp: '<p>Select one</p>',
                placeholderTxt: '',
                type: 'ck-checkbox',
                option: [
                    { id: '1', optionvalue: 'A holding deposit that has not been refunded', selected: false },
                    { id: '2', optionvalue: 'Default fees not specified in the tenancy agreement for late payment of rent', selected: false },
                    { id: '3', optionvalue: 'Default fees not specified in the tenancy agreement for lost key/ security device giving access to the housing', selected: false },
                    { id: '4', optionvalue: 'Viewing fees', selected: false },
                    { id: '5', optionvalue: 'Tenancy set-up fees', selected: false },
                    { id: '6', optionvalue: 'Check out fees', selected: false },
                    { id: '7', optionvalue: 'Third party fees', selected: false },
                ],
                value: '',
                position: '9',
                letterPosition: '7',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Time frame to return money',
                short_descp: '<p>Specify the number of days</p>',
                placeholderTxt: '',
                type: 'number',
                value: '',
                position: '10',
                letterPosition: '4',
                isValid: true,
                toolTip: '',
            },

        ],
        letterContaint: [
            {
                title: 'Subject: Return of banned fees under the Tenant Fees Act 2019',
                postion: 1,
                default: '',
                valueOF: '',
                alignment: 'left',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 2,
                default: 'Address of landlord / letting agent',
                valueOF: 'Address of landlord / letting agent',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 2,
                default: 'Postcode of landlord / letting agent',
                valueOF: 'Postcode of landlord / letting agent',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 3,
                default: moment(new Date()).format("DD/MM/YYYY"),
                valueOF: 'Date',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 4,
                default: 'Name of landlord / letting agent',
                valueOF: 'Name of landlord / letting agent',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: 'Dear ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 5,
                default: 'Your name',
                valueOF: 'Your name',
                alignment: 'center',
                rightdescription: ', I live at ',
                leftdescription: 'My name is ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 5,
                default: 'Address of property',
                valueOF: 'Address of property',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 5,
                default: 'Postcode of property',
                valueOF: 'Postcode of property',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

            {
                title: '',
                position: 7,
                default: 'Amount charged',
                valueOF: 'Amount charged',
                alignment: 'side-right',
                rightdescription: ' I was charged as ',
                leftdescription: 'I am writing to request the return of the ',
                default1: 'Type of charge',
                valueOF1: 'Type of charge',
                rightdescription1: ', which I believe is unlawful under the Tenant Fees Act 2019',
                leftdescription1: '',

            },
            {
                title: 'The Tenant Fees Act 2019 prohibits a landlord or agent of privately rented housing in England from requiring a tenant or any persons acting on their behalf or guaranteeing the rent, to make certain payments in connection with a tenancy. The fee charged does not amount to a permitted payment under the Act.',
                position: 9,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 10,
                default: 'Time frame to return money',
                valueOF: 'Time frame to return money',
                alignment: 'center',
                rightdescription: ' days from this date, or I will pursue this matter via the relevant enforcement authority or First-tier Tribunal. ',
                leftdescription: 'Please return this fee within ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'More information about the Tenant Fees Act is available at: https://www.gov.uk/government/collections/tenant-fees-act ',
                position: 11,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

            {
                title: 'I look forward to hearing from you soon.',
                position: 12,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

            {
                title: 'Yours sincerely ',
                position: 13,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 14,
                default: 'Your name',
                valueOF: 'Your name',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 15,
                default: 'Your address',
                valueOF: 'Address of property',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 15,
                default: 'Your postcode',
                valueOF: 'Postcode of property',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 16,
                default: 'Your contact details',
                valueOF: 'Your contact details',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

        ]
    },
    letterPoorCustomerService: {
        maxLeftIndex: 4,
        warning: false,
        description: '<p>This letter template can be used to write to your letting agent about poor customer service / misleading information (or another issue).</p>',
        builderInfo: {
            title: 'We do not store any of this data or send anything on your behalf',
            description: '<p>The following information is used purely to generate a personalised letter based on our template file. We will never send or store any of this data on your behalf.</p>'
        },
        generatedLetterInfo: {
            postion: 'top',
            title: '',
            description: ''
        },
        inputFields: [
            {
                title: 'Address of property',
                short_descp: '<p>First line of address</p>',
                placeholderTxt: '123 street name',
                type: 'text',
                value: '',
                position: '1',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Postcode of property',
                short_descp: '<p>Postcode.</p>',
                placeholderTxt: 'AB12 123',
                type: 'text',
                value: '',
                position: '2',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Name of letting agent',
                short_descp: '',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '3',
                letterPosition: '1',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Address of letting agent',
                short_descp: '',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '4',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Postcode of letting agent',
                short_descp: '',
                placeholderTxt: 'AB12 123',
                type: 'text',
                value: '',
                position: '5',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Your name',
                short_descp: '<p>Or the name of the tenant if on someone else’s behalf.</p>',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '6',
                letterPosition: '8',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Your contact details',
                short_descp: '<p>Or the contact details of the tenant if writing on someone else’s behalf.</p>',
                placeholderTxt: 'Phone number or email address',
                type: 'text',
                value: '',
                position: '7',
                letterPosition: '10',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Date when you first reported it',
                short_descp: '',
                placeholderTxt: '',
                type: 'date',
                value: '',
                position: '8',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'The issue you would like to complain about',
                short_descp: '',
                placeholderTxt: '',
                type: 'ck-editor',
                value: '',
                position: '9',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'What has gone wrong with the letting agent’s response?',
                short_descp: '',
                placeholderTxt: '',
                type: 'ck-editor',
                value: '',
                position: '10',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'How is it affecting you or your household?',
                short_descp: '',
                placeholderTxt: '',
                type: 'ck-editor',
                value: '',
                position: '11',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'How can they make things right?',
                short_descp: '',
                placeholderTxt: 'E.g. release you from your tenancy agreement if it included misleading information',
                type: 'ck-editor',
                value: '',
                position: '12',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            }
        ],
        letterContaint: [
            {
                title: '',
                postion: 1,
                default: 'Issue from action plan',
                valueOF: 'action_plan',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: 'Subject: Complaint regarding poor customer service / misleading information',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 2,
                default: 'Address of letting agent',
                valueOF: 'Address of letting agent',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 2,
                default: 'Postcode of letting agent',
                valueOF: 'Postcode of letting agent',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 3,
                default: moment(new Date()).format("DD/MM/YYYY"),
                valueOF: 'Date',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 4,
                default: 'Name of letting agent',
                valueOF: 'Name of letting agent',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: 'Dear ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 5,
                default: 'Address of property',
                valueOF: 'Address of property',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 5,
                default: 'Postcode of property',
                valueOF: 'Postcode of property',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'I am writing to make a complaint in relation to your management of the above property.',
                position: 6,
                default: 'The issue you would like to complain about',
                valueOF: 'The issue you would like to complain about',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 7,
                default: 'Date when you first reported it',
                valueOF: 'Date when you first reported it',
                alignment: 'side-right',
                rightdescription: ' However, I do not feel it has been resolved because: ',
                leftdescription: 'I originally contacted you about this on ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 8,
                default: 'What has gone wrong with the letting agent’s response?',
                valueOF: 'What has gone wrong with the letting agent’s response?',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 9,
                default: 'How is it affecting you or your household',
                valueOF: 'How is it affecting you or your household?',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

            {
                title: '',
                position: 10,
                default: 'How they can make things right.',
                valueOF: 'How can they make things right?',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: 'I have received information from the TDS Charitable Foundation “My Housing Issue Gateway”, and it is my understanding that you have obligations to address these issues. In order to make things right ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'Please also send me details of your complaints handling procedure.',
                position: 13,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'Yours sincerely',
                position: 14,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 15,
                default: 'Your name',
                valueOF: 'Your name',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'Tenant',
                position: 16,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 17,
                default: 'Your address',
                valueOF: 'Address of property',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 17,
                default: 'Your postcode',
                valueOF: 'Postcode of property',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 18,
                default: 'Your contact details',
                valueOF: 'Your contact details',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
        ]
    },
    letterRentIncrease: {
        maxLeftIndex: 3,
        warning: false,
        description: '<p>This letter template can be used to write to your letting agent about poor customer service / misleading information (or another issue).</p>',
        builderInfo: {
            title: 'We do not store any of this data or send anything on your behalf',
            description: '<p>The following information is used purely to generate a personalised letter based on our template file. We will never send or store any of this data on your behalf.</p>'
        },
        generatedLetterInfo: {
            postion: 'top',
            title: '',
            description: ''
        },
        inputFields: [
            {
                title: 'Address of property',
                short_descp: '<p>First line of address</p>',
                placeholderTxt: '123 street name',
                type: 'text',
                value: '',
                position: '1',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Postcode of property',
                short_descp: '<p>Postcode.</p>',
                placeholderTxt: 'AB12 123',
                type: 'text',
                value: '',
                position: '2',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Name of landlord / letting agent',
                short_descp: '',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '3',
                letterPosition: '1',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Address of landlord / letting agent',
                short_descp: '',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '4',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Postcode of landlord / letting agent',
                short_descp: '',
                placeholderTxt: 'AB12 123',
                type: 'text',
                value: '',
                position: '5',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Your name',
                short_descp: '<p>Or the name of the tenant if on someone else’s behalf.</p>',
                placeholderTxt: '',
                type: 'text',
                value: '',
                position: '6',
                letterPosition: '8',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Your contact details',
                short_descp: '<p>Or the contact details of the tenant if writing on someone else’s behalf.</p>',
                placeholderTxt: 'Phone number or email address',
                type: 'text',
                value: '',
                position: '7',
                letterPosition: '10',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Date you were notified of the rent increase',
                short_descp: '',
                placeholderTxt: '',
                type: 'date',
                value: '',
                position: '8',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Explain why this will be challenging for you to pay',
                short_descp: '',
                placeholderTxt: '',
                type: 'ck-editor',
                value: '',
                position: '9',
                letterPosition: '4',
                isValid: true,
                toolTip: '',
            },
            {
                title: 'Your proposed rent increase',
                short_descp: '<p>Let the landlord / letting agent know the amount you could afford</p>',
                placeholderTxt: '',
                type: 'number',
                value: '',
                position: '10',
                letterPosition: '3',
                isValid: true,
                toolTip: '',
            },

        ],
        letterContaint: [
            {
                title: '',
                postion: 1,
                default: 'Address of landlord / letting agent',
                valueOF: 'Address of landlord / letting agent',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                postion: 1,
                default: 'Postcode of landlord / letting agent',
                valueOF: 'Postcode of landlord / letting agent',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                postion: 2,
                default: moment(new Date()).format("DD/MM/YYYY"),
                valueOF: 'Date',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 3,
                default: 'Name of landlord / letting agent',
                valueOF: 'Name of landlord / letting agent',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: 'Dear ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                postion: 4,
                default: 'Address of property',
                valueOF: 'Address of property',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                postion: 4,
                default: 'Postcode of property',
                valueOF: 'Postcode of property',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 5,
                default: 'Date you were notified of the rent increase',
                valueOF: 'Date you were notified of the rent increase',
                alignment: 'normal',
                rightdescription: ' regarding a rent increase on the above rental, and am writing to let you know that I/we will find it extremely difficult, if not impossible, to pay the new monthly amount, ',
                leftdescription: 'I/we have received your notice on ',
                default1: 'Explain why this will be challenging for you to pay',
                valueOF1: 'Explain why this will be challenging for you to pay',
                rightdescription1: '',
                leftdescription1: 'because '
            },
            {
                title: 'We have enjoyed living in this property and would not like to move out, but I fear we would be forced to do so if faced with such a large monthly rent payment. Since we have been good tenants and always pay our rent on time, we hope you will take our situation into account, and be kind enough to reconsider.',
                position: 7,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 8,
                default: 'Your proposed rent increase',
                valueOF: 'Your proposed rent increase',
                alignment: 'side-right',
                rightdescription: '',
                leftdescription: 'We are asking that the new rent be ',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: 'Yours sincerely',
                position: 9,
                default: '',
                valueOF: '',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 10,
                default: 'Your name',
                valueOF: 'Your name',
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 11,
                default: 'Your address',
                valueOF: 'Address of property',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 11,
                default: 'Your postcode',
                valueOF: 'Postcode of property',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },
            {
                title: '',
                position: 12,
                default: 'Your contact details',
                valueOF: 'Your contact details',
                isHorizontal: true,
                alignment: 'normal',
                rightdescription: '',
                leftdescription: '',
                default1: '',
                valueOF1: '',
                rightdescription1: '',
                leftdescription1: ''
            },

        ]
    },
}