import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment/moment';

const CustomInput = ({ _key, label, type, value, onChange, _className, placeholder }) => {
  const [error, setError] = useState('');
  const [startDate, setStartDate] = useState(null);


  const handleChange = (event, type) => {

    let inputValue = type === 'date' ? moment(event).format('DD/MM/YYYY') : event.target.value;
    let errorMsg = '';

    if (inputValue.length > 100) {
      errorMsg = 'Maximum character limit exceeded';
    } else {
      switch (type) {
        case 'number':
          inputValue = inputValue.replace(/\D/g, '');
          break;
        case 'text':
          // Remove special characters except for allowed ones
          inputValue = inputValue;
          break;
        default:
          break;
      }
    }

    setError(errorMsg);
    onChange(inputValue);
  };


  return (
    <div className='field-content-container'>
       <h3 className='labelQuestion text-tittle-19'>
          {label == "" ? null : label}
        </h3>
      <div className={`inputComponent ${_className}`} key={_key} style={{ border: '0.094rem solid #000000', paddingLeft: '0.63rem', marginBottom: '0.63rem', marginTop: '0 !important' }}>
       

          {type === 'date' ? (
            // <input type="date" value={value} onChange={(e)=>handleChange(e,type)} className='text-15' placeholder={placeholder} style={ value === '' ? {padding: '0.9rem', boxShadow: 'none', outline: 'none', textTransform: 'uppercase', color: '#BCBCBC', backgroundColor: "#fff"} : {padding: '0.9rem', boxShadow: 'none', outline: 'none', textTransform: 'uppercase', backgroundColor: "#fff"}} />
            <div className='date-container'>
            <DatePicker
              portalId="date-container"
              placeholderText="DD/MM/YYYY"
              
              value={value !== "" ? moment(value, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''}
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => { handleChange(date, type); setStartDate(date) }} 
              popperClassName='calendar-container'
              popperPlacement="bottom"
              popperModifiers={{
                flip: {
                  enabled: false // don't allow it to flip to be above
                },
                preventOverflow: {
                    enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                },
                hide: {
                    enabled: false // turn off since needs preventOverflow to be enabled
                }
            }}
              
              />
              </div>
          ) : type === 'number' ? (
            <input type="number" value={value} onChange={(e) => handleChange(e, type)} className='text-15' placeholder={placeholder} style={{ padding: '0.9rem', flexGrow: 1, boxShadow: 'none', outline: 'none' }} />
          )
            : (
              <input type="text" maxLength={200} value={value} onChange={(e) => handleChange(e, type)} className={`text-15 ${label == '' ? 'col-12 col-md-12' : 'col-12 col-md-4'}`} placeholder={placeholder} style={{ padding: '0.9rem', flexGrow: 1, boxShadow: 'none', outline: 'none' }} />
            )}

      </div>
      {error && <p className='error-text' style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

CustomInput.propTypes = {
  _key: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['date', 'number', 'text', 'ck-editor']).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  _className: PropTypes.string, // Allow className to be optional
  placeholder: PropTypes.string, // Allow placeholder text to be optional
  selected: PropTypes.bool,
};

export default CustomInput;
