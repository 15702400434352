import React, { useEffect, useMemo, useRef } from 'react';
import JoditEditor from 'jodit-react';

const CustomCKEditor = ({ placeholder, value, onChange, isValid }) => {
  const editorClass = !isValid ? 'inputError' : '';
  const editor = useRef();

  const options = ['bold', 'italic', '|', 'fontsize', '|', 'outdent', 'indent', 'align', '|', 'undo', 'redo'];

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: value === '' ? placeholder : '',
      defaultActionOnPaste: 'insert_as_html',
      defaultLineHeight: 1.5,
      enter: 'p',
      uploader: { insertImageAsBase64URI: true },
      buttons: options,
      buttonsMD: options,
      buttonsSM: options,
      buttonsXS: options,
      statusbar: true,
      sizeLG: 900,
      sizeMD: 700,
      sizeSM: 400,
      showCharactersCounter: true,
      showWordsCounter: false,
      toolbarAdaptive: false,
      minWidth: '100%',
      maxWidth: '100%',
      width: '100%',
      limitChars: 500,
      askBeforePasteFromWord: false,
      askBeforePasteHTML: false,
      hidePoweredByJodit: true,
    }),
    [placeholder]
  );

  useEffect(() => {
    if (editor.current && editor.current.editor) {
      editor.current.value = value; // Ensure editor value is set on load
    }
  }, [value]);

  const handleBlur = (newValue) => {
    if (newValue !== value) {
      onChange(newValue); // Only update if the value has changed
    }
  };

  return (
    <div className={editorClass}>
      <JoditEditor
        ref={editor}
        value={value}
        config={config}
        tabIndex={1} // tabIndex of textarea
        onBlur={handleBlur} // Preferred to use only this option to update the content for performance reasons
      />
    </div>
  );
};

export default CustomCKEditor;

