import React, { useEffect, useState } from 'react'
import { GetHelpSection, HeroSection } from '../components'
import { useDispatch, useSelector } from 'react-redux'
import { fetchHomepageData } from './homepage/store/actions';
import { getData } from '../utils/storage';
import { Helmet } from 'react-helmet';
const Contact = () => {

  const externalSiteData = useSelector((state) => state.home);
  const [externalSiteList, setExternalSiteList] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    getData('homepageData').then((res) => {
      if (res !== undefined) {
        setExternalSiteList(res.contactExternalSites);
      } else {
        dispatch(fetchHomepageData());
      }
    }).catch((error) => console.log(error))
  }, [])

  useEffect(() => {
    if (externalSiteData.isSuccess && externalSiteData.data !== null) {
      setExternalSiteList(externalSiteData.data.success.data.contactExternalSites);
    }
  }, [externalSiteData])

  return (
    <div className='contact-content'>
      <Helmet>
        <title>Contact | My Housing Gateway</title>
        <meta property="og:title" content="Contact | My Housing Gateway" />
                <meta name="twitter:card" content="Contact | My Housing Gateway" />
        <meta description='If you have an issue with your rented property we can help by creating an action plan for you. This will explain the steps you should take in order to resolve your issue.' />
      </Helmet>
      <HeroSection title={'Contact us'} page={'contact'} />
      <GetHelpSection title={'Where can I get help?'} description={"Find other organisations for help and support here"} list={externalSiteList} />
    </div>
  )
}

export default Contact
