import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

export default function configureReduxStore() {
    const store = configureStore({ reducer: rootReducer,  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),})    
    sagaMiddleware.run(rootSaga);

    return store;
}