import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const WarningModal = (props) => {
    return (
        <Modal className='errorModal'
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className='redModal'>
      <Modal.Title id="contained-modal-title-vcenter">
        <div className='modalTitle'>
        {props.title}
        </div>
      </Modal.Title>
    </Modal.Header>
            <Modal.Body className='bg-white warning-modal'>
                
                    <p className='text-17'>We have detected that you may have included inflammatory language in your letter, which may include:</p>
                    <ul className='listStyleMsg'>
                        <li><p className='text-17'>Using a lot of CAPITAL letters</p></li>
                        <li><p className='text-17'>Using profane language (swear words)</p></li>
                    </ul>
                    <p className='text-17'>It is well documented that writing in a neutral, polite tone is much more effective for getting a positive result. 
Please check over your letter and agree that you have removed all examples of the above before generating your letter.</p>
        <div className='d-flex justify-content-between btnContainer'>
                <Button className='button without-arrow errorBtn' onClick={props.onSubmit}>{props.buttonText}</Button>
                <Button className='button without-arrow back-edit' onClick={props.onClose} style={{fontSize: "1.125rem", backgroundColor: "#00DD97"}}>Back to edit</Button>
                
            </div>
                
            </Modal.Body>
           
        </Modal>
    )
}

export default WarningModal;
