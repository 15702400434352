import React, { useEffect, useState } from 'react';
import BackToAction from '../general/backToAction';
import CustomInput from '../general/customInput';
import right_arrow from '../../assets/images/svg/arrow-right.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomCKEditor from '../general/CustomCKEditor';
import InformationContent from '../informationContent';
import leo from 'leo-profanity';
import WarningModal from '../general/warningModal';
import { getData, setData } from '../../utils/storage';
import TopTip from './topTip';
import { templeteData } from '../../data/templeteData';
import ErrorMessage from '../errorMessage';
const TelephoneSupportContainer = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [warning, setWarning] = useState(false);
  const [templateKey, setTemplateKey] = useState('');
  //background check questionset
  const [inputs, setInputs] = useState([]);


  useEffect(() => {
    if(location.state !== null && location.state.telephoneKey !== '' && location.state.telephoneKey !== undefined) {
      setInputs(templeteData[location.state.telephoneKey].inputFields)
      setWarning(templeteData[location.state.telephoneKey].warning)
    } else {
      getData('telephoneKey').then((res) => {
          if(res !== undefined && res !== '') {
            setInputs(templeteData[res].inputFields)
            setWarning(templeteData[res].warning)
            setTemplateKey(res)
             
          }
        }
      )
    }
  }, [])

  useEffect(() => { }, [
    // outcomeInput, issueInputs,
     inputs
  ]);

  useEffect(() => {

    if (location.state !== null && location.state.path === "telephone-prompt") {
      getData('inputFields').then((res) => {
        if (res !== undefined && res.length > 0) {
          setInputs(res);
        }
      }).catch((error) => console.log(error))
      
    }

  }, [])

  const goToPromptsPage = () => {
    let finalArry = inputs;
    let _profinatyCheck = false;
    let updatedData = finalArry.map(obj => {
      let inputValue = obj.type == 'ck-editor' ? leo.check(obj.value.replace(/<[^>]*>/g, '')) : leo.check(obj.value);
      // Check for uppercase words or sentences
      if (obj.type != 'date') {
        // Regular expression to match uppercase words with only alphabets and more than one letter
        const uppercaseWordRegex = /\b[A-Z]{2,}\b/;
        const containsUppercaseWord = uppercaseWordRegex.test(obj.value);

        if (inputValue) {
          _profinatyCheck = true;
          setInputs(prevInputs => prevInputs.map(input => (input.id == obj.id ? { ...input, isValid: false } : input)));
          return { ...obj, isValid: false }
        } else
          if (containsUppercaseWord) {
            _profinatyCheck = true;
            setInputs(prevInputs => prevInputs.map(input => (input.id == obj.id ? { ...input, isValid: false } : input)));
            return { ...obj, isValid: false }
          }
          else {
            setInputs(prevInputs => prevInputs.map(input => (input.id == obj.id ? { ...input, isValid: true } : input)));
            }
        return { ...obj, isValid: true }
      }
    });

    if (_profinatyCheck) {
      setShowModal(true);
      return;
    } else {
      setData('inputFields', inputs);
      setData('telephoneKey', templateKey);
      // setData('issueFields', issueInputs)
      // setData('outComeFields', outcomeInput)
      navigate('/get-advice/telephone-prompts', { state: { data: finalArry, templateType :location.state !== null && location.state.telephoneKey !== undefined ? location.state.telephoneKey : templateKey !== '' ? templateKey : ''}  })
    }
  }

  const handleInputChange = (id, newValue) => {
   // this method is used to replace the white background color with the off white color for jodit editor 
   let temp =  newValue.replace(/background-color:\s*rgb\(255,\s*255,\s*255\)/g, 'background-color: rgb(250, 250, 250)');
    
      setInputs(prevInputs =>
        prevInputs.map(input => (input.id === id ? { ...input, value: temp, isValid: newValue != '' } : input))
      );

  };

  const onClose = (e) => {
    setShowModal(false);
  }



  const discardChsnges = () => {
    setInputs(prevInputs => prevInputs.map(input => ({ ...input, value: '' })));
   }

  return (
    <div className='bg-cream telePhoneContainer'>
      <div className="container bg-white">
        <BackToAction link={"/get-advice/letter-builder"} />
        <div className='content-container col-12 col-md-12'>
          <h3 className='title-26 black'>Telephone support</h3>
          <p className='text-normal black'>As your recommendation was to make telephone call, you can use these prompts to help you with your conversation.</p>
          
          {warning ? (
            <div className='mb-3'>
              <ErrorMessage message="If you are in immediate danger you should contact the police on 999." link="" />
            </div>
            ) : null}
          <InformationContent title="We do not store any of this data or send anything on your behalf" description="The following notes you make are purely for your own use, we do not save these or send them anywhere on your behalf. You will be able to print and download your notes for your own records. " />
          <TopTip templateType={location.state !== null && location.state.telephoneKey !== undefined ? location.state.telephoneKey : templateKey !== '' ? templateKey : ''} />
        </div>
        <div className='firstQuestionSet content-container mb-4'>
         
          {inputs.map(input => {
            if (input.type !== 'ck-editor') {
              return (<CustomInput
                _key={input.id}
                label={input.label}
                type={input.type}
                _className={input._className}
                value={input.value}
                onChange={e => handleInputChange(input.id, e)}
                placeholder={input.placeholder}
                isValid={input.isValid}
              />)
            } else {
              return (
                <div className='ck-editor-container mt-4'>
                  <h3 className='text-tittle-19 mb-0'>{input.label}</h3>
                  <p dangerouslySetInnerHTML={{__html: input.description}} />
                  <CustomCKEditor
                    placeholder={input.value !== "" ? "" : input.placeholder}
                    value={input.value}
                    onChange={(data) => handleInputChange(input.id, data)}
                    isValid={input.isValid}
                  />
                </div>

              )
            }
          }


          )}
         
        </div>
        
        <div className='d-flex justify-content-between buttonContainer'>
          <button className='discardButton square border border-2 rounded text-20' onClick={() => discardChsnges()}>Clear all</button>
          <button className='generateButton text-20' onClick={() => goToPromptsPage()}>
            Generate prompts
            <img src={right_arrow} style={{ height: '2.563rem', width: '2.563rem', marginLeft: '1.5rem', verticalAlign: 'middle' }} />
          </button>
        </div>
        <WarningModal title="Warning message" show={showModal} buttonText="I agree, generate my prompts"
          onClose={e => onClose(e)} onSubmit={() =>{
            setData('inputFields', inputs);
            setData('telephoneKey', templateKey);
            // setData('issueFields', issueInputs)
            // setData('outComeFields', outcomeInput)
            navigate('/get-advice/telephone-prompts', { state: { data: inputs, templateType :location.state !== null && location.state.telephoneKey !== undefined ? location.state.telephoneKey : templateKey !== '' ? templateKey : ''}  })
          }} />
      </div>

    </div>
  )
}

export default TelephoneSupportContainer;