import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import { API_ENDPOINT, AUTH_HEADERS } from "../../../config/ApiConstants"
import * as types from "./types";
import { getNewAxiosInstance } from '../../../utils/axiosUtil';








function* fetchFaq() {
    try {
        const axiosInstance = getNewAxiosInstance();
        const response = yield call(axiosInstance.get, `${API_ENDPOINT}/faq`);
        yield put({ type: types.FAQ_LIST_RECEIVE, payload: response.data });
    } catch (e) {
        yield put({ type: types.FAQ_LIST_FAILURE, payload: { error: e.message } });
    }
}

export default function* fetchFaqsSaga() {
    yield takeLatest(types.FAQ_LIST_REQUEST, fetchFaq);
}