import React from 'react'

const QuestionTitle = (props) => {
  return (
    <div className='question-title bg-white'>
      {props.questionCount ? (
        <span className='text-18'>Question {props.questionCount} of {props.totalCount}</span>
      ) : null}
      <h2>{props.question}</h2>
    </div>
  )
}

export default QuestionTitle
