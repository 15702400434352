import { call, put, takeLatest } from 'redux-saga/effects';

import { API_ENDPOINT } from "../../../config/ApiConstants"
import * as types from "./types";
import { getNewAxiosInstance } from '../../../utils/axiosUtil';


function* sendEmailTemplate(res) {
    try {
        const axiosInstance = getNewAxiosInstance(res.payload);
        let obj = {
            responses: res.payload
        }
        const response = yield call(axiosInstance.post, `${API_ENDPOINT}/email/action-plan` , res.payload);
        yield put({ type: types.EMAIL_TEMPLATE_RECEIVE, payload: response.data });
    } catch (e) {
        yield put({ type: types.EMAIL_TEMPLATE_FAILURE, payload: { error: e.message } });
    }
}


export default function* sendEmailSaga() {
    yield takeLatest(types.EMAIL_TEMPLATE_REQUEST, sendEmailTemplate);
}