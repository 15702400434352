import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import "../node_modules/jquery/dist/jquery.min.js";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";


// import registerServiceWorker from "./serviceworker/registerServiceWorker";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { applyMiddleware, compose, createStore } from "redux";
import { apiMiddleware } from "redux-api-middleware";
import reducers from "./stores/reducers/";
import { Provider } from "react-redux";
import AppNavigation from "./navigation/AppNavigation";
import configureReduxStore from "./stores/index.js";

/**
 *
 *TODO:
 */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/**
 * Out of the box, the Redux store can only support the synchronous flow of data.
 * Using middleware like thunk helps support asynchronicity in a Redux application.
 * You can think of thunk as a wrapper for the store’s dispatch() method; rather than returning action objects,
 * we can use thunk action creators to dispatch functions or Promises.
 * Note that without thunks, synchronous dispatches are the default.
 */
// const store = configureReduxStore(
//   reducers,
//   composeEnhancers(applyMiddleware(apiMiddleware))
// );

const root = createRoot(document.getElementById("root"));

const router = createBrowserRouter(AppNavigation)

const store = configureReduxStore();

root.render(
  // <StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  // </StrictMode>
);

// registerServiceWorker();
