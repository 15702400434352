import React, { useEffect, useState, useRef } from 'react';
import { Dropdown, DropdownMenu } from 'react-bootstrap';

const DropdownContainer = (props) => {

  const [dropDownValue, setDropDownValue] = useState(
    props.dropdownvalue
      ? props.dropdownvalue
      : props.title === 'Topics:'
        ? props.options[0]?.value
        : props.options[0]?.value
  );
  const [topicDropDown, setTopicDropDownValue] = useState(
    props.dropdownvalue
      ? props.dropdownvalue
      : props.title === 'Topics:'
        ? props.options[0].title
        : props.options[0]
  );


  const dropdownToggleRef = useRef(null);

  useEffect(() => {
  }, [dropDownValue]);

  const handleTitleClick = () => {
    if (dropdownToggleRef.current) {
      dropdownToggleRef.current.click();
    }
  };

  return (
    <div className='d-flex justify-content-start align-items-center'>
      <div className={`btn-group ${props.colorClass ? props.colorClass : ""} align-items-center`} id='dropdown-container'>
        <div onClick={handleTitleClick} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', }}>
          <h4 className="text-sub-title mb-0 mr-2 fw-normal">{props.title}</h4>
          {props.title === 'Topics:' ? (
            <Dropdown>
              <Dropdown.Toggle ref={dropdownToggleRef} id="dropdown-basic" className={props.color ? `${props.color} text-sub-title` : "text-sub-title darkBlue"}>
                {topicDropDown ? topicDropDown : props.dropdownvalue ? props.dropdownvalue : props.title === 'Topics:' ? props.options[0].title : props.options[0]}
              </Dropdown.Toggle>
              <DropdownMenu className={`dropdown-menu ${props.class}`} id='housing'>
                {props.options.map((item, index) => {
                  return (
                    <Dropdown.Item key={index} as={"li"}><a href="#" onClick={(e) => { setTopicDropDownValue(e.target.textContent); props.onDropDownChange(item.title, item.slug) }} className={props.color ? `${props.color} dropdown-item text-sub-title` : "dropdown-item text-sub-title darkBlue"}>{item.title}</a></Dropdown.Item>
                  )
                })}
              </DropdownMenu>
            </Dropdown>
          ) : (
            <Dropdown>
              <Dropdown.Toggle ref={dropdownToggleRef} id="dropdown-basic" className={props.color ? `${props.color} text-sub-title` : "text-sub-title darkBlue"}>
                {props.dropdownvalue ? props.dropdownvalue : dropDownValue ? dropDownValue : props.options[0]?.value}
              </Dropdown.Toggle>
              <Dropdown.Menu className={`dropdown-menu ${props.class}`} id='housing'>
                {props.options.map((item, index) => {
                  return (
                    <Dropdown.Item key={index} as={"li"}><a href={`item`.item} onClick={(e) => { setDropDownValue(item.value); props.onDropDownChange(item.value, item.id) }} className={props.color ? `${props.color} dropdown-item text-sub-title` : "dropdown-item text-sub-title darkBlue"}>{item.value}</a></Dropdown.Item>
                  )
                })}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </div>
    </div>
  );
}

export default DropdownContainer;

