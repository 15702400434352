import React from 'react';
import { Link } from 'react-router-dom';

const emergencyPopup = new CustomEvent("emergency");

const HeroSection = (props) => {


    // function to redner the content for the hero section on homepage page, about us and contact us page 
    const __renderHeroDescription = (type) => {
        switch (type) {
            case 'homepage': { // homepage hero section content
                return (
                    <>
                        <p className='description text-normal white col-12 col-md-7 col-lg-4'>This platform was built by the TDS Charitable Foundation to help tenants navigate the complex solutions & schemes in place for resolving rental issues.</p>
                        <Link className="button" to="/get-advice" >Create a personalised plan</Link>
                        <p className='sub-description col-12 col-md-7 col-lg-4 fadeWhite'>If you think it is an emergency <a title="Find how to get help" className='fw-bold' onClick={(e) => { e.preventDefault(); window.dispatchEvent(emergencyPopup) }} style={{ cursor: 'pointer' }}>find how to get help.</a></p>
                    </>
                )
            }
                break;
            case 'about': {
                return (
                    <>
                        <p className='description text-normal white col-12 col-md-6 col-lg-6'>This platform was built to help everyone navigate the complex solutions & schemes in place for rental issues.</p>
                        <p className='description text-normal white col-12 col-md-6 col-lg-6 mb-4'>The TDS Charitable Foundation is on mission to raise standards in the private rented sector.</p>
                        <p className='description text-normal white col-12 col-md-6 col-lg-6 mb-1'>We deliver our mission by awarding grants to organisations, conducting research, and providing educational information to tenants and landlords.</p>
                    </>
                )
            }
            case 'privacy': {
                return (
                    <>
                        <h3 className='description title white col-12 col-md-7 col-lg-5'>Want more information about the TDS Charitable Foundation?</h3>
                        <a className="button" href={props.link} alt="Privacy policy" target='_blank'>Find out more</a>
                    </>
                )
            }
                break;
            case 'contact': {
                return (
                    <>
                        <p className='description text-normal white col-12 col-md-8 col-lg-6'>Got a question or suggestion for us? Get in touch with the TDS Charitable Foundation.</p>
                        <div className='d-flex contact justify-content-start flex-column flex-lg-row '>
                            <div className='contactPrt1'>
                                <div className='section1 section'>
                                    <p className='description email text-normal white col-12 mb-0'>Email</p>
                                    <p className='text-normal white col-12 mb-0'>
                                        <a href='mailto:info@tdsfoundation.org.uk' style={{ color: "#fff", display: 'inline-block' }}>info@tdsfoundation.org.uk</a>
                                    </p>
                                </div>
                                <div className='section2 section'>
                                    <p className='description phone text-normal white col-12 mb-0'>Phone</p>
                                    <p className=' text-normal white col-12 mb-0'>020 000 0000</p>
                                    <p className=' text-normal white col-12 mb-0'>Lines open 9am - 5pm, Mon to Fri</p>
                                </div>
                            </div>
                            <div className='contactPrt2'>
                                <div className='section1 section'>
                                    <p className='description post text-normal white col-12 mb-0'>Post</p>
                                    <p className=' text-normal white col-12 col-mb-4 mb-0'>TDS Charitable Foundation
                                        West Wing, First Floor,
                                        The Maylands Building,
                                        200 Maylands Avenue,
                                        Hemel Hempstead,
                                        HP2 7TG </p>
                                </div>
                            </div>
                        </div>

                    </>
                )
            }
        }
    }


    return (

        <div className="hero bg-blue">
            <div className='container'>
                <div className='content-container'>
                    {props.title ? (
                        <h3 className='title white col-12 col-md-7 col-lg-4'>{props.title}</h3>
                    ) : null}
                    {__renderHeroDescription(props.page)}

                </div>
            </div>
        </div>

    )
}

export default HeroSection
