import React from 'react'
import { TDSLogo } from '../../config/images'

const AboutContent = (props) => {
    return (
        <div className='about-content bg-white'>
            <div className='container'>
                <div className='col-12 col-md-7 col-lg-8'>
                    <img src={TDSLogo} alt='Logo' />
                    <div>
                        <p className='text-tittle-19 fw-normal inter-font'>“My Housing Issue” gateway is a website to help private and social housing tenants resolve their housing problems through signposting and tailored information provision. By helping people find a clear path to the appropriate forum for making complaints and resolving disputes, it aims to encourage early resolution, simplify access to redress, and increase tenants’ knowledge of their rights and available options. </p>
                        
                        <p className='text-tittle-19 fw-normal mb-0' inter-font>The TDS Charitable Foundation aims to improve standard in the private renting sector. It works to advance education about housing rights and obligations in general and encourage the use of alternative dispute resolution for more efficient and effective resolution of disputes between landlords and tenants. </p>
                        <p className='text-tittle-19 fw-normal inter-font mt-4'>It is funded mainly by donations from The Dispute Service – the leading Tenancy Deposit Scheme which operates on a non-profit basis across the UK. It operates in England and Wales and have funded organisations and projects across all regions. </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutContent
