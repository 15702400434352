import React from 'react'
import { Accordion } from 'react-bootstrap'

const AccordionContainer = (props) => {
  return (
    <div className='accordion-container'>
      <Accordion>

        {props.list.map((accordion, index) => {
          return (
            <Accordion.Item eventKey={index} key={index}>
              <Accordion.Header className='bg-white'>{accordion.faqQuestion}</Accordion.Header>
              {Array.isArray(accordion.faqResponse) ? (

                <Accordion.Body>
                  {accordion.faqResponse.map((item) => {
                    return item+" ";
                  })}
                </Accordion.Body>
              ) : (
                <Accordion.Body>
                  {accordion.faqResponse === undefined && accordion.faqQuestion === 'Postcode'  ? 'Not provided' : accordion.faqResponse }
                </Accordion.Body>

              )}
            </Accordion.Item>
          )
        })}
      </Accordion>
    </div>
  )
}

export default AccordionContainer

