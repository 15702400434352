import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { WhatsappShareButton, EmailShareButton } from 'react-share';

const ShareModal = ({ show, onHide }) => {
    const shareUrl = 'https://example.com/';
    const title = 'Example Website';

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Share</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-around">
                    <WhatsappShareButton url={shareUrl}>
                        <Button variant="outline-success">
                            WhatsApp
                        </Button>
                    </WhatsappShareButton>
                    <EmailShareButton url={shareUrl} subject={title}>
                        <Button variant="outline-primary">
                            Email
                        </Button>
                    </EmailShareButton>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ShareModal;
