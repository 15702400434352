import React from 'react'

const VideoContainer = (props) => {
  return (
    <div className='video-container'>
        <p className='text-21 video-text' id="video-text">Watch the video for more information</p>
        <div className='video'>
            <div dangerouslySetInnerHTML={{__html: props.code}} />
        </div>
    </div>
  )
}

export default VideoContainer
