import axios from "axios";
import { API_ENDPOINT,AUTH_HEADERS } from "../config/ApiConstants";

axios.defaults.baseURL = API_ENDPOINT;
axios.defaults.headers.post['Content-Type'] = 'application/json';

const __handleError = (error) => {
    if (error.response) {
  
      switch (error.response.status) {
        case 401:
        //   toast.error(error.response.data);
          // Redirect to login page
          setTimeout(() => {
            localStorage.clear();
            window.location.reload();
          }, 3000);
          break;
  
        case 403:
          // Redirect to forbidden page
  
          break;
  
        case 500:
          // Redirect to forbidden page
        //   toast.error("Internal server error");
          break;
  
        default:
          break;
      }
  
    }
    return Promise.reject(error);
  }


  export const getNewAxiosInstance = (token) => {
    let headers = AUTH_HEADERS;
    if (token !== null) {
        headers = AUTH_HEADERS
      
    }
  
  
    const axiosInstance = axios.create({ headers });
    axiosInstance.interceptors.response.use(
      response => response,
      error => __handleError(error)
    )
  
    // Create axios instance with headers
    return axiosInstance
  }