import React, { useEffect, useState } from 'react';

const BlogContainer = (props) => {

    const [showContent, setShowContent] = useState(false);

    const truncate = (text, maxLength) => {
        if (text.length <= maxLength) return text;
        return text.slice(0, maxLength) + '...';
    };

    const toggleContent = () => {
        setShowContent(!showContent);
    };


    let displayContent = showContent ? props.blogItem.description : truncate(props.blogItem.description, 500);
    return (
        <>
            <div className='blog '>
                <div className='bg-white'>
                    <h3 className='text-title-21 lightBlue mb-3 mb-md-4 '>{props.blogItem.title}</h3>
                    <div dangerouslySetInnerHTML={{ __html: displayContent }} style={{ fontFamily: "inter" }} />
                    {props.blogItem.description.length > 500 && (
                        <button className='btn btn-link topic-show-btn' onClick={toggleContent}>
                            {showContent ? 'Show less' : 'Show more'}
                        </button>
                    )}
                </div>
            </div>
        </>
    )
}

export default BlogContainer