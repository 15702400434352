import React from 'react';
import { HeroSection } from '../../components';
import { Helmet } from 'react-helmet';

const PrivacyPolicyPage = () => {
    return (

        <div className="policyPage">
            <Helmet>
                <title>Privacy Policy | My Housing Gateway</title>
                <meta property="og:title" content="Privacy Policy | My Housing Gateway" />
                <meta name="twitter:card" content="Privacy Policy | My Housing Gateway" />
                <meta description='If you have an issue with your rented property we can help by creating an action plan for you. This will explain the steps you should take in order to resolve your issue.' />
            </Helmet>
            <div className='contentPage'>
                <h3 className='text-24 title'>Privacy Policy</h3>
                <p className='text-18'>Effective Date: 28 June 2024 </p>
                <h3 className='text-tittle-18'>1. Information</h3>
                <p className='text-18'>Welcome to My Housing Gateway. We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and protect your data when you use our website to find the right support services.</p>
                <p className='text-18'>My Housing Gateway is a service operated by the TDS Charitable Foundation. You can find further information about the TDS Charitable Foundation’s privacy practices here: <a href="https://www.tdsfoundation.org.uk/" target='_blank'>https://www.tdsfoundation.org.uk/ </a></p>
                <h3 className='text-tittle-18'>2. Information We Collect </h3>
                <p className='text-18'>We only collect the following personal information: </p>
                <ul>
                    <li className='text-18'>Name</li>
                    <li className='text-18'>Postcode</li>
                    <li className='text-18'>Basic contact details (e.g., email address, phone number)</li>
                </ul>
                <h3 className='text-tittle-18'>3. How We Use Your Information</h3>
                <p className='text-18'>The information we collect is used solely for the purpose of: </p>
                <ul>
                    <li className='text-18'>Connecting you with appropriate support services.</li>
                    <li className='text-18'>Improving our service to better meet your needs. </li>
                </ul>
                <h3 className='text-tittle-18'>4. Data Storage and Retention </h3>
                <p className='text-18'>We store your personal information securely and retain it for a period of six months from the date of collection. After this period, your data will be permanently deleted from our systems. </p>
                <h3 className='text-tittle-18'>5. Data Security</h3>
                <p className='text-18'>We implement appropriate technical and organizational measures to protect your personal data from unauthorized access, disclosure, alteration, or destruction.</p>

                <h3 className='text-tittle-18'>6. Sharing Your Information </h3>
                <p className='text-18'>We do not share, sell, or distribute your personal information to third parties, except as required by law or with your explicit consent. </p>
                <h3 className='text-tittle-18'>7. Your Rights</h3>
                <p className='text-18'>You have the right to: </p>
                <ul>
                    <li className='text-18'>Access the personal information we hold about you.</li>
                    <li className='text-18'>Request the correction of any inaccurate data. </li>
                    <li className='text-18'>Request the deletion of your personal data before the six-month retention period, if desired. </li>
                </ul>
                <p className='text-18'>To exercise these rights, please contact us at <a href='mailto:GDPR@tdsgroup.uk'>GDPR@tdsgroup.uk</a> </p>

                <h3 className='text-tittle-18'>8. Changes to This Privacy Policy</h3>
                <p className='text-18'>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. </p>
                <h3 className='text-tittle-18'>9. Contact Us</h3>
                <p className='text-18'>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at: </p>
                <p className='text-18'>TDS Charitable Foundation </p>
                <p className='text-18'><a href='mailto:GDPR@tdsgroup.uk '>GDPR@tdsgroup.uk </a></p>
                <p className='text-18'>Thank you for trusting My Housing Gateway with your information. We are committed to protecting your privacy and providing you with the best service possible. </p>
            </div>
            <HeroSection page={"privacy"} link={"https://www.tdsfoundation.org.uk/about-us"} />
        </div>

    )
}
export default PrivacyPolicyPage;