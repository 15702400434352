import * as types from "./types";
import { setInitialState } from "../../../stores/InitialState";

const INITIAL_STATE = setInitialState({
    data: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    error: false
});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.EMAIL_TEMPLATE_REQUEST:
            return {
                ...state,
                isFetching: true,
                isSuccess: false,
                isError: false,
            };
        case types.EMAIL_TEMPLATE_RECEIVE:
            return {
                ...state,
                isFetching: false,
                isSuccess: true,
                isError: false,
                data: action.payload
            };
        case types.EMAIL_TEMPLATE_FAILURE:
            if (action.error && action.payload.name === "RequestError") {
                return {
                    ...state,
                    isError: true,
                    isFetching: false,
                    isSuccess: false,
                    data: { message: "INTERNET_OFFLINE_MESSAGE" }
                };
            } else {
                return {
                    ...state,
                    isFetching: false,
                    isError: true,
                    isSuccess: false,
                    data: action.payload.response.error,
                };
            };
        case types.RESET_EMAIL_TEMPLATE:
            return {
                ...state,
                isFetching: false,
                isError: false,
                data: null,
            };
        default:
            return {
                ...state
            }
    }
}