import React, { useState } from 'react';
import checkBoxOff from '../../assets/images/svg/checkbox_off.svg';
import checkBoxOn from '../../assets/images/svg/checkbox_on.svg';

const CustomCheckBox = ({ key, label, value, selected, onClick, type }) => {

  return (
    <div
      className={`checkBoxContainer ${selected ? 'selected' : ''}`}
      onClick={onClick}
      key={key}
      
    >
      <div className='d-flex flex-column flex-md-row justify-content-start '>
        {type === 'question' ? (
          <div className='d-flex justify-content-start mb-3 align-items-center'>
            <img
              src={selected ? checkBoxOn : checkBoxOff}
              style={{ width: '1.875rem', height: '1.875rem' }}
              className='me-3'
            />
            <div className={`descpStyle text-tittle-19-normal ${selected ? `slctStyle` : ``}`} dangerouslySetInnerHTML={{ __html: value }} />
          </div>
        ) : (
          <>
            <div className={`text-tittle-19 checkbox-title ${selected ? `slctStyle` : ``}`}>{label != '' ? label : '+'}</div>
            <div className={`descpStyle text-tittle-19-normal ${selected ? `slctStyle` : ``}`} dangerouslySetInnerHTML={{ __html: value }} />
          </>
        )

        }
      </div>
      {type !== 'question' ? (
      <img
        src={selected ? checkBoxOn : checkBoxOff}
        style={{ width: '1.875rem', height: '1.875rem', zIndex: 1 }}
      />
    ) : null}
    </div>
  );
};

export default CustomCheckBox;
