import { call, put, takeLatest } from 'redux-saga/effects';

import * as types from "./types";
import { getNewAxiosInstance } from '../../../utils/axiosUtil';
import { API_ENDPOINT } from '../../../config/ApiConstants';

function* fetchTopics() {
    try {
        const axiosInstance = getNewAxiosInstance();
        const response = yield call(axiosInstance, `${API_ENDPOINT}/topic`);
        yield put({ type: types.TOPICS_RECEIVE, payload: response.data });
    } catch (e) {
        yield put({ type: types.TOPICS_FAILURE, payload: { error: e.message } });
    }
}

export default function* fetchTopicsSaga() {
    yield takeLatest(types.TOPICS_REQUEST, fetchTopics);
}