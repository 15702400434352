import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Accordion } from 'react-bootstrap'

const InformationContent = (props) => {


    const [showDescription, setShowDescription] = useState(true);

    useEffect(() => {
        function handleResize() {
            if (window.outerWidth > 767) {
                setShowDescription(true)
            }
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, [])

    return (
        <>
            <div className='information col-12 d-none d-md-block'>

                <h3 className='text-tittle-18 information-title'> {props.title ? props.title : "We do not store any of this data or send anything on your behalf" }</h3>
                {/* </div> */}
                <div className='information-content'>
                    {showDescription ? (
                        
                        <p className='description grey'>{ props.description ? <p dangerouslySetInnerHTML={{__html: props.description}} /> : "The following information is used purely to generate a personalised letter based on our template file. We will never send or store any of this data on your behalf."}</p>
                    ) : null}
                    <div className='d-flex justify-content-end d-block d-md-none'>
                        <Button className={showDescription ? 'show hide-button  me-4 mb-3' : 'hide-button  me-4 mb-3'} onClick={() => setShowDescription(!showDescription)}> {showDescription ? 'Hide' : 'Read information'}  </Button>
                    </div>
                </div>
            </div>
            <div className='letter-info information col-12 col-md-8 d-block d-md-none'>
                <Accordion className='information '>
                    <Accordion.Item>
                        <Accordion.Header as={"h3"} className='information-title'>{props.title ? props.title : "This service might not be right for you"}</Accordion.Header>
                        <Accordion.Body as={"p"} className='text-18 fw-normal information-content'>
                           {props.description ? <p dangerouslySetInnerHTML={{__html: props.description}} /> : "The following information is used purely to generate a personalised letter based on our template file. We will never send or store any of this data on your behalf."}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    )
}

export default InformationContent
