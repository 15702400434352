import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { EmergencyLogo } from '../config/images';

const EmergencyModal = (props) => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered

            className='emergency'
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4 className='red'>
                    <img src={EmergencyLogo} alt="Emergency info" />
                    Who to call in the case of an emergency</h4>
                <p>
                    <b>If you are reporting a crime that is in progress or if someone is in immediate danger</b> - Call the Police on <span className='red'><strong>999</strong></span></p>
                <p><b>Gas leak</b> - Call National Gas <span className='red'><strong>0800 111 999</strong></span></p>

                <p><b>Water leak</b> - Call your local water company</p>

                <p style={{color: "#212529"}}><b>Health and Safety</b> - <a style={{color: "#d4351c", fontWeight: 700}} href='https://www.gov.uk/find-local-council' target='_blank'>Find your local Council’s emergency number</a>
                </p>
            </Modal.Body>
        </Modal>
    )
}

export default EmergencyModal
