import React, { useEffect, useState } from 'react';
import { Chart, CommonPorblems, HeroSection, Homelessness, Loader } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHomepageData, resetHomepageData } from './store/actions';
import { setData } from '../../utils/storage';
import { Helmet } from 'react-helmet';

const Homepage = () => {

  const [dropdownOption, setDropdDownOption] = useState([]);
  const [commonCards, setCommonCards] = useState();
  const [homelessCards, setHomelessCards] = useState([]);
  const dispatch = useDispatch();
  const homepageData = useSelector(state => state.home);


  useEffect(() => {
    dispatch(fetchHomepageData());
  }, [])


  useEffect(() => {
    if (homepageData.isSuccess && homepageData.data !== null) {
      let rentingType = homepageData.data.success.data.rentingTypes;
      let commonCardsList = homepageData.data.success.data.commonProblems;
      let homelessCardsList = homepageData.data.success.data.homelessnessExternalSites;
      for (let rentingData in rentingType) {
        let obj = {
          id: rentingData,
          value: rentingType[rentingData]
        }
        const objLen = dropdownOption.filter((item) => item.id === obj.id);

        if (!objLen.length > 0) {
          setDropdDownOption((current) => [...current, obj]);
        }
      }
      setCommonCards(commonCardsList);
      setHomelessCards(homelessCardsList);
      setData('homepageData', homepageData.data.success.data);
      dispatch(resetHomepageData())
    } else if (homepageData.isError) {
      dispatch(resetHomepageData())
    }
  }, [homepageData])

  return (
    <div>
      <Helmet>
        <title>Home | My Housing Gateway</title>
        <meta property="og:title" content="Home | My Housing Gateway" />
        <meta name="twitter:card" content="Home | My Housing Gateway" />
        <meta description='If you have an issue with your rented property we can help by creating an action plan for you. This will explain the steps you should take in order to resolve your issue.' />
      </Helmet>
      <div className="homepage d-flex flex-column" style={{ width: '100%' }}>
        {homepageData.isFetching ? (
          <Loader message={"Loading....."} />
        ) : homepageData.isSuccess ? (
          <div>
            <HeroSection title={'Need help resolving a tenancy related issue?'} page={'homepage'} description={'description'} link={'link'} sub={'sub'} />
            {commonCards !== undefined ? Object.keys(commonCards).length > 0 ? (
              <CommonPorblems dropdown={dropdownOption} commonCards={commonCards} />
            ) : null : null}
            <Chart dropdown={dropdownOption} />
            {Array.isArray(homelessCards) && homelessCards.length > 0 && homelessCards !== undefined ? (
              <Homelessness homelessCards={homelessCards} />
            ) : null}
          </div>
        ) : null}

      </div>
    </div>
  )
}

export default Homepage
