import React from 'react'
import { Helmet } from 'react-helmet'

const Error = () => {
  return (
    <div className='d-flex flex-column align-items-center justify-content-center'>
      <Helmet>
        <title>404</title>
        <meta property="og:title" content="404" />
                <meta name="twitter:card" content="404" />
        <meta description='If you have an issue with your rented property we can help by creating an action plan for you. This will explain the steps you should take in order to resolve your issue.' />
      </Helmet>
      <h1>404</h1>
      <p>Page not found</p>
    </div>
  )
}

export default Error
