import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import DropdownContainer from '../dropdown';

const TopicsList = (props) => {

  const [activeTopic, setActiveTopic] = useState();
  const [scrollEnable, setScrollEnable] = useState(false);
  const [isAtEnd, setIsAtEnd] = useState(false);


  const location = useParams();
  const ref = useRef();
  const itemRefs = useRef([]);

  useEffect(() => {
    itemRefs.current = itemRefs.current.slice(0, props.topicListData.length); // Adjust length of refs array
  }, [props.topicListData.length]);

  const handleScrollToItem = (index) => {
    if (itemRefs.current[index]) {
      itemRefs.current[index].scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (location.subtopics && itemRefs.current.length > 0) {
      const itemIndex = props.topicListData.findIndex(item => item.slug === location.subtopics); // Assuming items have 'id' property
      if (itemIndex !== -1 && itemRefs.current[itemIndex]) {

        const element = itemRefs.current[itemIndex];
        const scrollContainer = element.parentElement; // Assuming the parent element is the scrollable container

        const targetScrollLeft = element.offsetLeft - 10; // Adjust for horizontal scroll

        // Scroll container to the target position
        scrollContainer.scrollTo({
          left: scrollContainer.scrollLeft + targetScrollLeft,
          behavior: 'smooth',
        });

        // itemRefs.current[itemIndex].scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.subtopics, props.topicListData])

  useEffect(() => {
    props.topicListData.map((item) => {
      if (item.slug === location.subtopics) {
        setActiveTopic(item.title);
      }
    })
  }, [location])


  useEffect(() => {
    const scrollFunc = () => {

      setTimeout(() => {
        let { scrollWidth, clientWidth, scrollLeft } = ref.current;
        setScrollEnable(scrollWidth > clientWidth);
        setIsAtEnd(scrollLeft + clientWidth >= scrollWidth);

      }, 100)
    }

    scrollFunc();

    window.addEventListener('load', scrollFunc);
    window.addEventListener('resize', scrollFunc);
    return () => {
      window.removeEventListener('load', scrollFunc);
      window.removeEventListener('resize', scrollFunc);
    }

  }, [])

  const scrollTo = (offset) => {
    if (ref.current) {
      ref.current.scrollLeft += offset;
      // handleScroll()
    }
  };

  const scrollToLeft = (offset) => {
    if (ref.current) {
      ref.current.scrollLeft -= offset;
      // handleScroll()
    }
  };

  // const handleScroll = () => {
  //   if (ref.current) {
  //     const { scrollWidth, clientWidth, scrollLeft } = ref.current;
  //     setIsAtEnd(scrollLeft + clientWidth > scrollWidth - 1);
  //   }
  // };




  return (
    <div className='topicList bg-blue'>
      <div className={`container topic-list-container`} >
        {scrollEnable ? (
          <button className='list-scroll  left' onClick={() => scrollToLeft(150)}></button>
        ) : null}
        <ul className={`d-none d-md-flex justify-content-start ${scrollEnable ? 'scroll-data' : ''} `} ref={ref}>

          {props.topicListData.map((item, index) => {
            return <><li key={index} className='me-5' ref={(el) => (itemRefs.current[index] = el)}>
              <Link className={location.subtopics === item.slug ? 'active white' : 'white'} to="#" onClick={() => { props.onTopicChange(item.title, item.slug) }} > {item.title}</Link>
            </li>
            </>
          })}
        </ul>
        {scrollEnable ? (
          <button className='list-scroll' onClick={() => scrollTo(150)}></button>
        ) : null}
        <div className='d-flex d-md-none justify-content-start mobile-dropdown'>
          {Array.isArray(props.topicListData) && props.topicListData.length > 0 ? (
            <DropdownContainer title={'Topics:'} colorClass={''} dropdownvalue={props.title} options={props.topicListData ?? props.topicListData} onDropDownChange={props.onTopicChange} />
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default TopicsList
