import React from 'react'
import { Link, useLocation } from 'react-router-dom';

const Cards = (props) => {

    const location = useLocation();

    const __renderCards = (type, title, description, link, id, target, dropdownDownVal) => {
        switch (type) {
            case 'association':
                return (
                    <li className="bg-white cards col-5 col-md-3 mt-4 green-border d-flex flex-column">
                        {title && title !== '' ? (
                            <h4 className="text-sub-title d-none d-md-block">{title}</h4>
                        ) : null}
                        {description && description !== '' ? (
                            <p className="text-normal lightGrey">{description}</p>
                        ) : null}
                        {link && title !== '' ? (
                            <Link onClick={() => props.onClick(id, title)} to={'/get-advice/action-plan'} state={{ prevPath: location.pathname, title: title, dropdown: dropdownDownVal }} className='text-sub-title lightBlue select' title="Hot water problems">Select</Link>
                        ) : null}
                    </li>
                )
                break;
            case 'site':
                return (
                    <li className='bg-white cards col-5 col-md-3 mt-4 green-border d-flex flex-column'>
                        {props.title ? (
                            <h4 className="text-sub-title lightGrey">{props.title}</h4>
                        ) : null}
                        {props.description ? (
                            <p>{props.description}</p>
                        ) : null}
                        {props.link ? (
                            <a href={props.link ? props.link : "https://www.google.com"} target='_blank' className="green text-sub-title fw-normal inter-font">Visit site</a>
                        ) : null}
                    </li>
                )
                break;
            case 'case':
                return (
                    <li className='bg-white case cards col-12 col-md-6 mt-4 green-border d-flex flex-column flex-grow-1 flex-md-grow-0'>
                        {props.title ? (
                            <h4 className="text-sub-title lightGrey">{props.title}</h4>
                        ) : null}
                        {props.description ? (
                            <p className='text-18 fw-normal inter-font'>{props.description}</p>
                        ) : null}
                    </li>
                )
                break;
            case 'topic':
                return (
                    <li className='bg-white cards col-12 col-md-6 mt-4 green-border d-flex justify-content-center flex-column'>
                        {props.title ? (
                            <Link className="text-sub-title lightGrey mb-0 flex-grow-1 d-flex align-items-center" to={`/topics/${props.link}`} target={target === '_blank' ? target : '_self'}>
                                {props.title}
                            </Link>

                        ) : null}
                    </li>
                )
                break;
            default:
                return null
        }
    }

    return (__renderCards(props.type, props.title, props.description, props.link, props.id, props.target, props.dropdownDownVal));
}

export default Cards
