import React, { useMemo, useRef, useState } from 'react'
import Cards from '../cards'

const Homelessness = (props) => {
   

    return (

        <div className='bg-light homelessness'>
            <div className='container'>
                <h3 className='title lightGrey'>Worried about homelessness?</h3>
                <p className='text-normal lightGrey inter-font mb-2'>If you have been evicted from your home, have nowhere to stay tonight or are homeless you should contact Shelter or your local Council immediately. </p>
                <ul className="card-container d-flex justify-content-between flex-wrap justify-content-md-start">
                    {Array.isArray(props.homelessCards) && props.homelessCards.length > 0 ? (
                        <>
                            {props.homelessCards.map((item) => {
                                return <Cards type='site' title={item.externalSiteTitle} link={item.externalSiteLink} />
                            })}
                        </>
                    ) : null}
                   
                </ul>
            </div>
        </div>
    )
}

export default Homelessness
