import React from 'react'
import { Form } from 'react-bootstrap'

const RadioField = (props) => {
  
  return (
    <div className='radio-container'>
      <Form.Check 
      type={'radio'} 
      checked={props.checked}  
      value={props.value !== "" ? props.value : props.label}  
      id={props.id} 
      label={props.label} 
      name={props.questionId} 
      onChange={(e) => props.radioChange(props.questionId,props.id,true)} />
    </div>
  )
}

export default RadioField
