import React, { useEffect, useState } from 'react'
import { TDSLogo } from '../config/images'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavLink, Link } from 'react-router-dom';
import EmergencyModal from './modal';

const Header = () => {

    const [headerCollapse, setHeaderCollapse] = useState(false);
    const [windowWidth, setWindowWidth] = useState();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {

        function windowResize() {
            setWindowWidth(window.outerWidth);
        }

        window.addEventListener('load', windowResize);
        window.addEventListener('resize', windowResize);

        window.addEventListener('emergency', () => { setShowModal(!showModal) })
        return () => {
            window.removeEventListener('load', windowResize);
            window.removeEventListener('resize', windowResize);
            window.removeEventListener('emergency', (function () { })())
        };

    }, [])

    useEffect(() => {
    }, [headerCollapse])

    const collapseMenu = () => {

        if (window.innerWidth < 992) {
            setHeaderCollapse(false)
        }
    }

    const showEmergencyModal = () => {
        setShowModal(true)

        if (window.innerWidth < 992) {
            setHeaderCollapse(false)
        }
    }

    const toggleCollapse = () => {
        setHeaderCollapse(!headerCollapse)
    }


    return (
        <header>


            <Navbar expand="lg" expanded={headerCollapse} className="bg-body-tertiary" >
                <Container>
                    <Nav.Link className='navbar-brand' as={Link} to="/" onClick={() => collapseMenu()}>
                        <img src={TDSLogo} alt="Logo" />
                    </Nav.Link>
                    <Navbar.Toggle onClick={() => toggleCollapse()} aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav justify-content-end">
                        <Nav className="me-auto justify-content-end flex-grow-1">
                            <NavLink className="nav-link" onClick={(e) => collapseMenu()} to="/get-advice">Resolve an issue</NavLink>
                            <NavLink className="nav-link" onClick={(e) => collapseMenu()} to="/topics">Topics</NavLink>
                            <NavLink className="nav-link" onClick={(e) => collapseMenu()} to="/faq">FAQ</NavLink>
                            <NavLink className="nav-link" onClick={(e) => collapseMenu()} to="/about">About</NavLink>
                            {/* <NavLink  className="nav-link" onClick={(e) => collapseMenu()} to="/contact">Contact</NavLink> */}
                            <NavLink className="nav-link" onClick={(e) => showEmergencyModal()} to="#">Emergency</NavLink>

                        </Nav>
                    </Navbar.Collapse>
                    <EmergencyModal
                        show={showModal}
                        onHide={() => setShowModal(false)}
                    />
                </Container>
            </Navbar>

        </header>
    )
}

export default Header
