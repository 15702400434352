import { combineReducers } from '@reduxjs/toolkit';

// import all the reducers from the application
import questionSet from '../../screens/questionSet/store/reducer/questionSet';
import optionCreate from '../../screens/questionSet/store/reducer/optionCreate';
import faq from "../../screens/faq/store/reducers";
import homepage from "../../screens/homepage/store/reducers";
import topics from "../../screens/topics/store/reducers";
import actionPlan from "../../screens/actionPlan/store/reducers/reducers";
import commonActionReducers from '../../screens/actionPlan/store/reducers/commonActionReducers';
import generateLetter from "../../screens/generateLetter/store/reducers";


// combine all reducers together
const appReducer = combineReducers({
    questionSet: questionSet,
    faqList: faq,
    home: homepage,
    topicsDataList: topics,
    options: optionCreate,
    actionPlanData: actionPlan,
    commonActionData: commonActionReducers,
    email: generateLetter,
});


const rootReducer = (state, action) => {
    return appReducer(state, action)
}
export default rootReducer;