import React from 'react'
import left_arrow from '../../assets/images/svg/arrow-left.svg';
import { useNavigate } from 'react-router-dom';

const BackToAction = (props) => {

    const navigate = useNavigate();

    return(
        <div className='backActionContainer'>
             <button className='backActionButton' onClick={() => navigate("/get-advice/action-plan")}>
            <img src={left_arrow} className='leftArrowStyle' />
                Back to my action plan
              </button>
        </div>

    )
}

export default BackToAction;