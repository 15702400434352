import React, { useEffect, useState } from 'react';
import { GetHelpSection, TelephoneSupportContainer } from '../components';
import { getData } from '../utils/storage';
import { Helmet } from 'react-helmet';

const TelephoneSupportPage = () => {

  const [externalLinksData, setExternalLinksData] = useState([]);

  useEffect(() => {

    const getExternalLinksData = async () => {
      const response = await getData('generalExternalSites');
      // const data = await response.json();
      setExternalLinksData(response);
    }
    getExternalLinksData()
  }, [])

  useEffect(() => {
  }, [externalLinksData])
  return (

    <div className="telephoneSupportPage">
      <Helmet>
        <title>Get Advice | Telephone Support | My Housing Gateway</title>
        <meta property="og:title" content="Get Advice | Telephone Support | My Housing Gateway" />
                <meta name="twitter:card" content="Get Advice | Telephone Support | My Housing Gateway" />
        <meta description='If you have an issue with your rented property we can help by creating an action plan for you. This will explain the steps you should take in order to resolve your issue.' />
      </Helmet>

      <TelephoneSupportContainer />
      <GetHelpSection title='Where can I get help?' description='If you need alternative help or advice, please visit one of these websites' list={externalLinksData} />

    </div>

  )
}
export default TelephoneSupportPage;
