import * as type from "./types"

export const fetchQuestionData = () => {
    return { type: type.QUESTION_LIST_REQUEST, payload: null }
}

export const resetQuestionData = () => {
    return { type: type.RESET_QUESTION_LIST, payload: null }
}


// actions for the option fetch for action plan question 

export const fetchOptions = (param) => {
    return { type: type.OPTIONS_FETCH_REQUEST, payload: param }
}

export const resetFetchOptions = () => {
    return { type: type.RESET_OPTIONS_FETCH, payload: null }
}
