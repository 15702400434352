import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import { API_ENDPOINT, AUTH_HEADERS } from "../../../config/ApiConstants"
import * as types from "./types";
import { getNewAxiosInstance } from '../../../utils/axiosUtil';


// common problem reducer for the data 

function* fetchCommonActionPlan(res) {
    try {
        const axiosInstance = getNewAxiosInstance(res);
        const response = yield call(axiosInstance.get, `${API_ENDPOINT}/common-problem/${res.payload}/action-plan`);
        yield put({ type: types.COMMON_ACTION_PLAN_RECEIVE, payload: response.data });
    } catch (e) {
        yield put({ type: types.COMMON_ACTION_PLAN_FAILURE, payload: { error: e.message } });
    }
}


function* fetchAllActionPlan(res) {
    try {
        const axiosInstance = getNewAxiosInstance(res.payload);
        let obj = {
            responses: res.payload
        }

        const response = yield call(axiosInstance.post, `${API_ENDPOINT}/generate-action-plan`, JSON.stringify(res.payload));
        yield put({ type: types.ACTION_PLAN_RECEIVE, payload: response.data });
    } catch (e) {
        yield put({ type: types.ACTION_PLAN_FAILURE, payload: { error: e.message } });
    }
}

export default function* fetchActionPlanSaga() {
    yield takeLatest(types.ACTION_PLAN_REQUEST, fetchAllActionPlan);
    yield takeLatest(types.COMMON_ACTION_PLAN_REQUEST, fetchCommonActionPlan);
}