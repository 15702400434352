import React from 'react'
import { LoaderImage, Loadergif } from '../../config/images'

const Loader = (props) => {
  return (
    <div className='loader flex-grow-1'>
      <img src={Loadergif} alt='Loader' />
      <h2 className='mt-3'>{props.message}</h2>
    </div>
  )
}

export default Loader
