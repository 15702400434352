import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import { API_ENDPOINT, AUTH_HEADERS } from "../../../config/ApiConstants"
import * as types from "./types";
import { getNewAxiosInstance } from '../../../utils/axiosUtil';

function* fetchQuestions() {
    try {
        const axiosInstance = getNewAxiosInstance();
        const response = yield call(axiosInstance.get, `${API_ENDPOINT}/questions`);
        yield put({ type: types.QUESTION_LIST_RECEIVE, payload: response.data });
    } catch (e) {
        yield put({ type: types.QUESTION_LIST_FAILURE, payload: { error: e.message } });
    }
}


function* fetchOptions(res) {
    try {
        const axiosInstance = getNewAxiosInstance(res.payload);
        let obj = {
            responses: res.payload
        }
        const response = yield call(axiosInstance.post, `${API_ENDPOINT}/questions/action-step-question` , obj);
        yield put({ type: types.OPTIONS_FETCH_RECEIVE, payload: response.data });
    } catch (e) {
        yield put({ type: types.OPTIONS_FETCH_FAILURE, payload: { error: e.message } });
    }
}


export default function* fetchQuestionsSaga() {
    yield takeLatest(types.QUESTION_LIST_REQUEST, fetchQuestions);
    yield takeLatest(types.OPTIONS_FETCH_REQUEST, fetchOptions);
}