import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'

const RadioDropdown = (props) => {

    const [menuOpen, setMenuOpen] = useState(true);
    const [selectVal, setSelectVal] = useState("");


  return (
    <div className='d-block d-md-none bg-white radio-dropdown'>
      <Dropdown >
      <Dropdown.Toggle variant="success" id="dropdown-basic" className='d-block toggle-button'>
        {selectVal ? selectVal : props.options[0]?.title}
      </Dropdown.Toggle>

      <Dropdown.Menu >
        {props.options?.map((item, index) => {
            return <Dropdown.Item key={index} as={'button'} className={props.title === item.title ? "active" : ""} onClick={(e) => { setSelectVal(e.target.textContent); props.onChange(item.title, item.slug)}}>{item.title}</Dropdown.Item>
        })}
      </Dropdown.Menu>
    </Dropdown>
    </div>
  )
}

export default RadioDropdown
