import React from 'react'

const Infoasking = (props) => {
  return (
    <div className='info-asking mt-3 mt-md-5 mb-5 col-md-8'>
      <h2 className='text-18 fw-bold'>Why are we asking this?</h2>
      <p className='inter-font text-18 fw-normal'>{props.askingText}</p>
    </div>
  )
}

export default Infoasking
