import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ErrorMsgModal = (props) => {
    return (
        <Modal className='errorModal'
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className='redModal'>
                <Modal.Title id="contained-modal-title-vcenter">
                    <div className='modalErrorTitle'>
                        {props.title}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <p className='text-17'>{props.msg}</p>

                <div className='d-flex justify-content-between btnContainer'>
                    <Button className='button without-arrow ' onClick={props.onClose}>OK</Button>

                </div>

            </Modal.Body>

        </Modal>
    )
}

export default ErrorMsgModal;
