import React from 'react'
import { Accordion } from 'react-bootstrap'

const QuestionErrorMessage = (props) => {
  return (
    <div className='question-error'>
      <Accordion>
        <Accordion.Item>
            <Accordion.Header className='bg-red'>{props.title ? props.title : "This service might not be right for you"}</Accordion.Header>
            <Accordion.Body className='text-18 fw-normal'>
              {Array.isArray(props.errorMessage) && props.errorMessage.length > 0 ? (
                <ul className='error-list' style={{listStyleType: 'disc'}}>
                  {props.errorMessage.map((item) => {
                    return <li><p dangerouslySetInnerHTML={{__html: item}} /></li>
                  })}
                </ul>
              ) : <p dangerouslySetInnerHTML={{__html: props.errorMessage}} />}
            </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}

export default QuestionErrorMessage
