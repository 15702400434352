import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import { API_ENDPOINT, AUTH_HEADERS } from "../../../config/ApiConstants"
import * as types from "./types";
import { getNewAxiosInstance } from '../../../utils/axiosUtil';

function* fetchHomepage() {
    try {
        const axiosInstance = getNewAxiosInstance();
        const response = yield call(axiosInstance, `${API_ENDPOINT}/home`);
        yield put({ type: types.HOMEPAGE_RECEIVE, payload: response.data });
    } catch (e) {
        yield put({ type: types.HOMEPAGE_FAILURE, payload: { error: e.message } });
    }
}

export default function* fetchHomepageSaga() {
    yield takeLatest(types.HOMEPAGE_REQUEST, fetchHomepage);
}