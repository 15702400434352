import * as types from "../types";
import { setInitialState } from "../../../../stores/InitialState";

const INITIAL_STATE = setInitialState({
    data: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    error: false
});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.COMMON_ACTION_PLAN_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case types.COMMON_ACTION_PLAN_RECEIVE:
            return {
                ...state,
                isFetching: false,
                isSuccess: true,
                data: action.payload
            };
        case types.COMMON_ACTION_PLAN_FAILURE:
            if (action.error && action.payload.name === "RequestError") {
                return {
                    ...state,
                    isError: true,
                    isFetching: false,
                    data: { message: "INTERNET_OFFLINE_MESSAGE" }
                };
            } else {
                return {
                    ...state,
                    isFetching: false,
                    isError: true,
                    isSuccess: false,
                    data: action.payload.response.error,
                };
            };
        case types.RESET_COMMON_ACTION_PLAN:
            return {
                ...state,
                isFetching: false,
                isError: false,
                data: null,
            };
        default:
            return {
                ...state
            }
    }
}