import React, { useEffect, useState } from 'react'
import Cards from '../cards'

const GetHelpSection = (props) => {


    const homelessNessData = [
        {
            externalSiteTitle: 'Shelter',
            externalSiteLink: '#'
        },
        {
            externalSiteTitle: 'Law works',
            externalSiteLink: '#'
        },
        {
            externalSiteTitle: 'Citizens Advice',
            externalSiteLink: '#'
        },
    ]

    const [cardsList, setCardsList] = useState([]);

    useEffect(() => {
        if (props.list) {
            setCardsList(props.list)
        } else {
            setCardsList(homelessNessData)
        }
    }, [props])


    return (

        <div className={props.color ? `${props.color} homelessness` : 'bg-light homelessness'}>
            <div className='container'>
                {props.title ? (
                    <h3 className='title lightGrey'>{props.title}</h3>
                ) : null}
                {props.description ? (
                    <p className='text-normal lightGrey'>{props.description} </p>
                ) : null}
                
                    <ul className="card-container d-flex justify-content-between flex-wrap justify-content-md-start">
                        {cardsList.map((item) => {
                            return <Cards type='site' title={item.externalSiteTitle} link={item.externalSiteLink} />
                        })}
                    </ul>
            </div>
        </div>
    )
}

export default GetHelpSection
