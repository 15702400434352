import React from 'react'
import { Link } from 'react-router-dom'

const ErrorMessage = (props) => {
  return (
    <div className='bg-red error-container'>
        <p className='white text-18'>
        {props.message} {props.link ? (<a href="https://www.google.com" className='text-18 white'>Find out more</a>) : null} 
        </p>
    </div>
  )
}

export default ErrorMessage
