import { all, fork, takeEvery} from "redux-saga/effects";
import fetchQuestionsSaga from "../../screens/questionSet/store/saga";
import fetchFaqsSaga from "../../screens/faq/store/saga";
import fetchHomepageSaga from "../../screens/homepage/store/saga";
import fetchTopicsSaga from "../../screens/topics/store/saga";
import fetchActionPlanSaga from "../../screens/actionPlan/store/saga";
import sendEmailSaga from "../../screens/generateLetter/store/saga";

function* rootSaga() {
    yield all([
        fork(fetchQuestionsSaga),
        fork(fetchFaqsSaga),
        fork(fetchHomepageSaga),
        fork(fetchTopicsSaga),
        fork(fetchActionPlanSaga),
        fork(sendEmailSaga),
    ])
}

export default rootSaga;