import * as type from "./types"


// actions for the option fetch for action plan question 

export const emailTemplate = (param) => {
    return { type: type.EMAIL_TEMPLATE_REQUEST, payload: param }
}

export const resetEmailTemplate = () => {
    return { type: type.RESET_EMAIL_TEMPLATE, payload: null }
}
