import React from 'react'
import TDSLogo from '../assets/images/svg/TDS-Logo.svg';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer>
    <div className="container d-flex justify-content-center align-items-center align-items-md-start justify-content-md-start flex-column flex-md-row">
        <Link className='col-md-2 logo' to="/" title='TDS'>
            <img src={TDSLogo} alt="Logo" />
        </Link>    

        <p className='col-md-6 description'>TDS Charitable Foundation 2024. All Rights Reserved</p>

        <ul className='col-md-4 d-flex justify-content-end flex-wrap footer-list'>
            {/* <li className='text-right'>
              <Link to="/cookie-policy">Cookie Policy</Link>
              </li> */}
            {/* <li className='text-right'><Link to="/contact">Contact Us</Link></li> */}
            <li className='text-right'><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li className='text-right'><Link to="/about">About TDS</Link></li>

        </ul>
    </div>

</footer>
  )
}

export default Footer
